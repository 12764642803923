import React, { useEffect, useState } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Props} from "../types/props";

import en from "../locales/en/translation.json";
import es from "../locales/es/translation.json";
import ro from "../locales/ro/translation.json";

export const TranslationContext = React.createContext({});

export const Translation: React.FC<Props> = ({ children }) => {
  const [lang, setLang] = useState<string | null>(null);

  useEffect(() => {
    const localStoragelang = localStorage.getItem("lang");
    setLang(localStoragelang);
  }, [JSON.stringify(localStorage)]);

  i18n.use(initReactI18next).init({
    lng: lang || navigator.language,
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      ro: {
        translation: ro,
      },
    },
    fallbackLng: "en",
    react: { useSuspense: false },
  });

  return (
    <TranslationContext.Provider value={lang || navigator.language}>
      {children}
    </TranslationContext.Provider>
  );
};

export default Translation;
