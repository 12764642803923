import React, { useEffect, useState } from "react";
import { Token } from "../types/token";
import { Props} from "../types/props";

type AuthContextData = {
  logOut: () => void | null;
  authToken: string | null;
  getAccessToken: () => Promise<string | null> | null;
  storeToken: (token: any) => void | null;
};

export const AuthContext = React.createContext<AuthContextData>({
  logOut: () => null,
  authToken: null,
  getAccessToken: () => null,
  storeToken: () => null,
});

export const useAuth = () => React.useContext(AuthContext);

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [authToken, setAuthToken] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      const token = await localStorage.getItem("token");
      setAuthToken(token);
    })();
  }, []);

  const storeToken = (token: Token) => {
    setAuthToken(token.access);
    localStorage.setItem("refresh", token.refresh);
    localStorage.setItem("token", token.access);
  };

  const logOut = () => {
    localStorage.clear();
    setAuthToken(null);
  };

  const getAccessToken = async () => {
    const token = localStorage.getItem("token");
    return token;
  };

  const value: AuthContextData = {
    logOut,
    authToken,
    getAccessToken,
    storeToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
