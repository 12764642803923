import React, { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import CarouselVideo from "../../components/carousel-video/CarouselVideo";

import styles from "./Faq.module.css";

const FAQ: React.FC<{}> = () => {
  const { t } = useTranslation();

  const howCanIRegisterAnAccountRef = useRef<null | HTMLDivElement>(null);
  const whatCanIDoIfIForgetMyPasswordRef = useRef<null | HTMLDivElement>(null);
  const howCanIChangeMyPasswordRef = useRef<null | HTMLDivElement>(null);
  const howCanILinkMyEmailRef = useRef<null | HTMLDivElement>(null);
  const howCanIChangeMyNicknameRef = useRef<null | HTMLDivElement>(null);
  const howCanIJoinAClubRef = useRef<null | HTMLDivElement>(null);
  const howToCreateAClubRef = useRef<null | HTMLDivElement>(null);
  const howCanISwitchRef = useRef<null | HTMLDivElement>(null);
  const IAmAMemberRef = useRef<null | HTMLDivElement>(null);

  const [tutorialsVisible, setTutorialsVisible] = useState(true);

  const executeScroll = (ref: React.MutableRefObject<HTMLDivElement | null>) =>
    ref?.current?.scrollIntoView(true);

  const handleSetTutorialVisible =() => {
    // console.log("handleSetTutorials");
    setTutorialsVisible((prevState)=> !prevState);
  }

  return (
    <Container fluid className={styles.faq}>
      <Row className={styles.topRow}>
        {tutorialsVisible ? <Container className={styles.topRowContainer}>
            <p className={styles.collapseButton} onClick={handleSetTutorialVisible}>
              {t('hideTutorials')}
            </p>
            <p className={styles.tutosTitle}>
            {t('watchTutorials')}
            </p>
            <p className={styles.tutosTitle}>
            <a 
              href='https://www.youtube.com/@betnclubs'
              target='_blank'>{t('moreInOurChannel')}</a>
            
            </p>
            <CarouselVideo></CarouselVideo>
          </Container>
         : <Container className={styles.topRowContainer}>
         <p className={styles.collapseButton} onClick={handleSetTutorialVisible}>
         {t('hideTutorials')}
         </p>
         <p className={styles.tutosTitle}>
            {t('watchTutorials')}
            </p>
       </Container>}
          
      </Row>
      <Row>
        <Col xl={3} lg={3} className={`d-lg-block d-none ${styles.leftCol}`}>
        <div  className={styles.limiterDiv}>
          <div className={styles.leftColTop}>
            <Row>
              <h1 className={`${styles.header1} ${styles.mgBottom50} `}>
                {t("Users")}
              </h1>
            </Row>
            <Row>
              <h3
                className={`${styles.header1} ${styles.header3} ${styles.mgBottom30}`}
              >
                <a onClick={() => executeScroll(howCanIRegisterAnAccountRef)}>
                  {t("HowCanIRegisterAnAccount")}
                </a>
              </h3>
            </Row>
            <Row>
              <h3
                className={`${styles.header1} ${styles.header3} ${styles.mgBottom30}`}
              >
                <a
                  onClick={() =>
                    executeScroll(whatCanIDoIfIForgetMyPasswordRef)
                  }
                >
                  {t("WhatCanIDoIfIForgetMyPassword")}
                </a>
              </h3>
            </Row>
            <Row>
              <h3
                className={`${styles.header1} ${styles.header3} ${styles.mgBottom30}`}
              >
                <a onClick={() => executeScroll(howCanIChangeMyPasswordRef)}>
                  {t("HowCanIChangeMyPassword")}
                </a>
              </h3>
            </Row>
            <Row>
              <h3
                className={`${styles.header1} ${styles.header3} ${styles.mgBottom30}`}
              >
                <a onClick={() => executeScroll(howCanILinkMyEmailRef)}>
                  {t("HowCanILinkMyEmail")}
                </a>
              </h3>
            </Row>
            <Row>
              <h3 className={`${styles.header1} ${styles.header3}`}>
                <a onClick={() => executeScroll(howCanIChangeMyNicknameRef)}>
                  {t("HowCanIChangeMyNickname")}
                </a>
              </h3>
            </Row>
          </div>
          </div>
          <div  className={styles.limiterDiv}>
          <div className={styles.leftColBottom}>
            <Row>
              <h1 className={`${styles.header1} ${styles.mgBottom50}`}>
                {t("Clubs")}
              </h1>
            </Row>
            <Row>
              <h1
                className={`${styles.header1} ${styles.header3} ${styles.mgBottom30}`}
              >
                <a onClick={() => executeScroll(howCanIJoinAClubRef)}>
                  {t("HowCanIJoinAClub")}
                </a>
              </h1>
            </Row>
            <Row>
              <h3
                className={`${styles.header1} ${styles.header3} ${styles.mgBottom30}`}
              >
                <a onClick={() => executeScroll(howToCreateAClubRef)}>
                  {t("HowToCreateAClub")}
                </a>
              </h3>
            </Row>
            <Row>
              <h3
                className={`${styles.header1} ${styles.header3} ${styles.mgBottom30}`}
              >
                <a onClick={() => executeScroll(IAmAMemberRef)}>
                  {t("IAmAMember")}
                </a>
              </h3>
            </Row>
            <Row>
              <h3 className={`${styles.header1} ${styles.header3}`}>
                <a onClick={() => executeScroll(howCanISwitchRef)}>
                  {t("HowCanISwitch")}
                </a>
              </h3>
            </Row>
          </div>
          </div>
        </Col>
        <Col xl={9} lg={9} md={9} sm={12} xs={12}>
          <Row>
            <h2 className={`${styles.header1} ${styles.header2}`}>
              {t("FrequentlyAskedQuestions")}
            </h2>
          </Row>
          <Row>
            <h2
              className={`${styles.header1} ${styles.header2} ${styles.mgTop40}`}
            >
              {t("Users")}
            </h2>
          </Row>
          <Row ref={howCanIRegisterAnAccountRef} className={styles.padScroll}>
            <h4 className={`${styles.header4} ${styles.mgTop30}`}>
              {t("HowCanIRegisterAnAccount")}
            </h4>
          </Row>
          <Row>
            <p className={styles.answerText}>
              {t("HowCanIRegisterAnAccountAnswer")}
            </p>
          </Row>
          <Row  ref={whatCanIDoIfIForgetMyPasswordRef} className={styles.padScroll}>
            <h4 className={`${styles.header4} ${styles.mgTop30}`}>
              {t("WhatCanIDoIfIForgetMyPassword")}
            </h4>
          </Row>
          <Row>
            <p className={styles.answerText}>
              {t("WhatCanIDoIfIForgetMyPasswordAnswer")}
            </p>
          </Row>
          <Row ref={howCanIChangeMyPasswordRef} className={styles.padScroll}>
            <h4 className={`${styles.header4} ${styles.mgTop30}`}>
              {t("HowCanIChangeMyPassword")}
            </h4>
          </Row>
          <Row>
            <p className={styles.answerText}>
              {t("HowCanIChangeMyPasswordAnswer")}
            </p>
          </Row>
          <Row ref={howCanILinkMyEmailRef} className={styles.padScroll}>
            <h4 className={`${styles.header4} ${styles.mgTop30}`}>
              {t("HowCanILinkMyEmail")}
            </h4>
          </Row>
          <Row>
            <p className={styles.answerText}>{t("HowCanILinkMyEmailAnswer")}</p>
          </Row>
          <Row ref={howCanIChangeMyNicknameRef} className={styles.padScroll}>
            <h4 className={`${styles.header4} ${styles.mgTop30}`}>
              {t("HowCanIChangeMyNickname")}
            </h4>
          </Row>
          <Row >
            <p className={styles.answerText}>
              {t("HowCanIChangeMyNicknameAnswer")}
            </p>
          </Row>
          <Row>
            <h2
              className={`${styles.header1} ${styles.header2} ${styles.mgTop40}`}
            >
              {t("Clubs")}
            </h2>
          </Row>
          <Row  ref={howCanIJoinAClubRef} className={styles.padScroll}>
            <h4 className={`${styles.header4} ${styles.mgTop30}`}>
              {t("HowCanIJoinAClub")}
            </h4>
          </Row>
          <Row>
            <p className={styles.answerText}>{t("HowCanIJoinAClubAnswer")}</p>
          </Row>
          <Row ref={howToCreateAClubRef} className={styles.padScroll}>
            <h4 className={`${styles.header4} ${styles.mgTop30}`}>
              {t("HowToCreateAClub")}
            </h4>
          </Row>
          <Row >
            <p className={styles.answerText}>{t("HowToCreateAClubAnswer")}</p>
          </Row>
          <Row ref={IAmAMemberRef} className={styles.padScroll}>
             <h4 className={`${styles.header4} ${styles.mgTop30}`}>
              {t("IAmAMember")}
            </h4>
          </Row>
          <Row >
            <p className={styles.answerText}>{t("IAmAMemberAnswer")}</p>
          </Row>
          <Row ref={howCanISwitchRef} className={styles.padScroll}>
            <h4 className={`${styles.header4} ${styles.mgTop30}`}>
              {t("HowCanISwitch")}
            </h4>
          </Row>
          <Row >
            <p className={styles.answerText}>{t("HowCanISwitchAnswer")}</p>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FAQ;
