import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { IoCheckmark, IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import axios from "axios";

import { useUserProfile } from "../../context/UserProfileProvider";
import { useAxios } from "../../context/AxiosProvider";

import AccountConfirmationModal from "../../components/modals/account-confirmation/AccountConfirmationModal";

import styles from "./Profile.module.css";
import { ODDS_FORMATS } from "../../constants/odds-formats";

const Profile: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { currentUser } = useUserProfile();
  const { ChangeEmail } = useAxios();

  const [name, setName] = useState<string>(currentUser?.username ?? "");
  const [oddsFormat, setOddsFormat] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [
    isAccountConfirmationModalVisible,
    setIsAccountConfirmaitionModalVisible
  ] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        setLocation(response.data.country_name);
      })
      .catch((error: any) => {});
    return () => {
      setIsError(false);
      setErrorMessage("");
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      const oddsFormatValue = ODDS_FORMATS.find(
        (format) => format.id === currentUser?.odds_format
      );
      setName(currentUser?.username);
      setOddsFormat(oddsFormatValue!.name);
      setEmail(currentUser?.email);
      setIsEmailVerified(currentUser?.email_verified);
    }
  }, [JSON.stringify(currentUser)]);

  const handleVerifyEmail = async () => {
    try {
      await ChangeEmail(email);
      setIsAccountConfirmaitionModalVisible(true);
    } catch (error: any) {
      setIsError(true);
      setErrorMessage(error?.response?.data?.detail);
    }
  };

  if (!localStorage.getItem("token")) return <Navigate to='/' />;

  return (
    <Container fluid className={styles.profile}>
      <Row>
        <Col md={7}>
          <h1 className={styles.header1}>profile</h1>
          <Form>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Row>
                <Col lg={12} md={12} xs={12}>
                  <p className={`${styles.uppercase} ${styles.labelText}`}>
                    {t("UserId")} {currentUser?.public_id}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} xs={12}>
                  <Form.Label className={styles.labelText}>
                    {t("Name")}
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    readOnly={true}
                    className={styles.formInput}
                    required
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Row>
                <Col lg={12} md={12} xs={12}>
                  <Form.Label className={styles.labelText}>
                    {t("OddsFormat")}
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    readOnly={true}
                    className={styles.formInput}
                    required
                    value={oddsFormat}
                    onChange={(event) => setOddsFormat(event.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Row>
                <Col lg={12} md={12} xs={12}>
                  <Form.Label className={styles.labelText}>
                    {t("Location")}
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    readOnly={true}
                    className={styles.formInput}
                    required
                    value={location}
                    onChange={(event) => setLocation(event.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Row>
                <Col lg={12} md={12} xs={12}>
                  <Form.Label className={styles.labelText}>
                    {t("Email")}
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col className={styles.row}>
                  <Form.Control
                    className={styles.formInput}
                    required
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  {isEmailVerified ? (
                    <IoCheckmark
                      size={20}
                      color={"green"}
                      className={styles.icon}
                    />
                  ) : (
                    <IoClose size={20} color={"red"} className={styles.icon} />
                  )}
                </Col>
              </Row>
            </Form.Group>
            <Row className={styles.errorMessageContainer}>
              <Col lg={12} md={12} xs={12}>
                <p
                  className={isError ? styles.errorMessage : styles.displayNone}
                >
                  {errorMessage}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <a className={styles.labelText} onClick={handleVerifyEmail}>
                  {t("VerifyYourEmailAddress")}
                </a>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <AccountConfirmationModal
        email={email}
        isAccountConfirmationModalVisible={isAccountConfirmationModalVisible}
        handleSetIsAccountConfirmationCodeModalVisible={
          setIsAccountConfirmaitionModalVisible
        }
      />
    </Container>
  );
};

export default Profile;
