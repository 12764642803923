import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  linkWithCredential,
  signInWithPopup
} from "firebase/auth";

import {
  auth,
} from "../../../firebase";
import { getWebParams } from "../../../helpers/functions/getWebParams";
import { getCountryAndLanguageCode } from "../../../helpers/functions/getCountryAndLanguageCode";

import { useAxios } from "../../../context/AxiosProvider";

import _ from "lodash";

import styles from "./ConfirmJoinModal.module.css";

const ConfirmJoinModal: React.FC<{
  isConfirmJoinModalVisible: boolean;
  providerName: string;
  otherProvider: any;
  otherProviderName: string;
  pendingCred: any;
  handleSetIsConfirmJoinModalVisible: (value: boolean) => void;
}> = ({
  isConfirmJoinModalVisible,
  providerName,
  otherProvider,
  otherProviderName,  
  pendingCred,
  handleSetIsConfirmJoinModalVisible,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [language, setLanguage] = useState<string>();
  const { t } = useTranslation();
  const { UserGoogleLogin, UserFacebookLogin, UserAppleLogin } = useAxios();

  useEffect(() => {
    async function getLanguageAndCountry() {
      const { countryCode, languageCode } = await getCountryAndLanguageCode();
      setCountry(countryCode);
      setLanguage(languageCode);
    }
    getLanguageAndCountry();
    return () => {
      setIsError(false);
      setErrorMessage("");
    };
  }, []);
  
  
  const handleConfirmJoin = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
      try {
        // sign in with previously used social network, and link networks in Firebase
        const otherProviderRes = await signInWithPopup(auth, otherProvider);
        linkWithCredential(otherProviderRes.user, pendingCred!).then(function(new_credential : any) {
        }, function(error : any) {
          setIsError(true);
          setErrorMessage(error.response.data.detail);
        });
       // console.log("Link social succesful");
        
        // hide modal
        handleSetIsConfirmJoinModalVisible(false);

        // auto singIn the user, with the previos method, not the new selected
        // (these become equivalent after linking, and using previous methods
        // is more convenient due to the usage of previous response)
        singInAfterLinkSocial(otherProviderRes);

        // inform the user (poorly)
        setErrorMessage(t("NewAccountLinked"));

        return true;
      } catch (error: any) {
        setIsError(true);
        setErrorMessage(error.response.data.detail);
        return false
      }    
  };

  const singInAfterLinkSocial = async (otherProviderRes: any) => {
    const app_version = "web";
    const link_social = true;
    if (otherProviderName == "GOOGLE") {
      const username: string = otherProviderRes.user?.displayName?.replace(
        /\s/g,
        ""
      )!;
      const signinPayload = {
        google_object: { user: otherProviderRes?.user },
        device_info: getWebParams({ username: username }, "LOG_IN"),
        country,
        language,
        app_version,
        link_social,
        // create_account
      };
      await UserGoogleLogin(signinPayload);
      return;
    } else if (otherProviderName == "APPLE") {
      const username: string = otherProviderRes?.user?.displayName?.replace(
        /\s/g,
        ""
      )!;
      const signinPayload = {
        apple_object: { user: otherProviderRes?.user },
        device_info: getWebParams({ username: username }, "LOG_IN"),
        username,
        language,
        app_version,
        link_social,
        // create_account
      };
      await UserAppleLogin(signinPayload);
      return ;

    } else {
      const username: string = otherProviderRes?.user?.displayName?.replace(
        /\s/g, 
        ""
      )!;
      const signinPayload = {
        facebook_object: { user: otherProviderRes?.user },
        device_info: getWebParams({ username: username }, "LOG_IN"),
        username,
        country,
        language,
        app_version,
        link_social,
        // create_account
      };
      await UserFacebookLogin(signinPayload);
      return;
    }
  }

  return (
      <Modal
        show={isConfirmJoinModalVisible}
        onHide={() => {
          handleSetIsConfirmJoinModalVisible(false);
        }}
      >
        <Modal.Header closeButton className={styles.modalOnTop}>
        <p className={styles.labelTextHeader}>
          {t("LinkSocialTitle", {providerName: providerName})}
        </p>
        </Modal.Header>
        <Modal.Body className={`${styles.modalOnTop} ${styles.modalBody}`}>
          <Row>
            <Col lg={12} md={12} xs={12}>
              <p className={styles.labelText}>
                {t("YouHaveLogged", {providerName: providerName})}
                 <br></br>
                {t("IfYouConfirm")}
              </p>
            </Col>
          </Row>
          <Form onSubmit={handleConfirmJoin}>
            <Row className={styles.errorMessageContainer}>
              <Col lg={12} md={12} xs={12}>
                <p className={isError ? styles.errorMessage : styles.displayNone}>
                  {errorMessage}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <Button
                  type="submit"
                  onClick={handleConfirmJoin}
                  className={`${styles.text} ${styles.button} ${styles.confirmButton}`}
                >
                  {t("AcceptLinkAccounts")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
  );
};

export default ConfirmJoinModal;