import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import _ from "lodash";

import { useAxios } from "../../../context/AxiosProvider";

import styles from "./ForgotPasswordModal.module.css";

const ForgotPasswordModal: React.FC<{
  isForgotPasswordModalVisible: boolean;
  handleSetIsForgotPasswordModalVisible: (value: boolean) => void;
  handleSetIsRecoveryCodeModalVisible: (value: boolean) => void;
  handleSetNewPasswordEmail: (value: string) => void;
}> = ({
  isForgotPasswordModalVisible,
  handleSetIsForgotPasswordModalVisible,
  handleSetIsRecoveryCodeModalVisible,
  handleSetNewPasswordEmail
}) => {
  const { t } = useTranslation();

  const [validated, setValidated] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);

  const { ForgotPassword } = useAxios();

  useEffect(() => {
    setValidated(false);
    setIsError(false);
  }, []);

  const handleSendCode = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    } else {
      event.preventDefault();
      if (!_.isEmpty(email)) {
        try {
          await ForgotPassword(email!);
          setValidated(false);
          handleSetNewPasswordEmail(email!);
          setEmail("");
          setValidated(false);
          handleSetIsForgotPasswordModalVisible(false);
          handleSetIsRecoveryCodeModalVisible(true);
        } catch (error) {
          setIsError(true);
          setValidated(false);
        }
      }
      setValidated(true);
    }
    setValidated(true);
  };

  return (
    <Modal
      show={isForgotPasswordModalVisible}
      onHide={() => {
        handleSetIsForgotPasswordModalVisible(false);
        setEmail("");
        setValidated(false);
        setIsError(false);
      }}
      validated={validated}
      noValidate
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={12} md={12} xs={12}>
            <p className={styles.labelText}>{t("WeWillSendYouACode")}</p>
          </Col>
        </Row>
        <Form onSubmit={handleSendCode} noValidate validated={validated}>
          <Form.Group>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <Form.Label className={styles.labelText}>
                  {t("EnterYourEmailAddress")}
                </Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  type='email'
                  required
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Col>
            </Row>
          </Form.Group>
          <Row className={styles.errorMessageContainer}>
            <Col lg={12} md={12} xs={12}>
              <p className={isError ? styles.errorMessage : styles.displayNone}>
                {t("EmailDoesNotExist")}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} xs={12}>
              <Button
                type='submit'
                onClick={handleSendCode}
                className={`${styles.text} ${styles.button} ${styles.sendButton}`}
              >
                {t("Send")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ForgotPasswordModal;
