import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import ReactCodeInput from "react-verification-code-input";
import { useTranslation } from "react-i18next";

import { useAxios } from "../../../context/AxiosProvider";
import { useUserProfile } from "../../../context/UserProfileProvider";

import _ from "lodash";

import styles from "./AccountConfirmationModal.module.css";

const AccountConfirmationModal: React.FC<{
  isAccountConfirmationModalVisible: boolean;
  email: string;
  handleSetIsAccountConfirmationCodeModalVisible: (value: boolean) => void;
}> = ({
  isAccountConfirmationModalVisible,
  email,
  handleSetIsAccountConfirmationCodeModalVisible
}) => {
  const { t } = useTranslation();

  const { ConfirmEmail, ChangeEmail } = useAxios();
  const { getCurrentUserProfile } = useUserProfile();

  const [validated, setValidated] = useState<boolean>(false);
  const [code, setCode] = useState<string>();
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setValidated(false);
    setIsError(false);
  }, []);

  const handleConfirmCode = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    } else {
      event.preventDefault();
      if (Boolean(code && code.length === 6)) {
        try {
          await ConfirmEmail(code!);
          await getCurrentUserProfile();
          setCode("");
          setValidated(false);
          handleSetIsAccountConfirmationCodeModalVisible(false);
          await getCurrentUserProfile();
        } catch (error: any) {
          setIsError(true);
          setErrorMessage(error.response.data.detail);
          setValidated(false);
        }
      }
      setValidated(true);
    }
    setValidated(true);
  };

  return (
    <Modal
      show={isAccountConfirmationModalVisible}
      onHide={() => {
        handleSetIsAccountConfirmationCodeModalVisible(false);
        setCode("");
        setValidated(false);
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={12} md={12} xs={12}>
            <p className={styles.labelText}>{t("WeHaveSentYouACode")}</p>
          </Col>
        </Row>
        <Form onSubmit={handleConfirmCode} noValidate validated={validated}>
          <Form.Group>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <Form.Label className={styles.labelText}>Enter</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col
                lg={12}
                md={12}
                xs={12}
                className={styles.codeInputContainer}
              >
                <ReactCodeInput
                  className={styles.codeInput}
                  onChange={(value) => {
                    _.isEmpty(value) && setValidated(false);
                    setCode(value);
                  }}
                  onComplete={() => {
                    setValidated(true);
                  }}
                />
              </Col>
            </Row>
          </Form.Group>
          <Row className={styles.errorMessageContainer}>
            <Col lg={12} md={12} xs={12}>
              <p className={isError ? styles.errorMessage : styles.displayNone}>
                {errorMessage}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} xs={12}>
              <Button
                type='submit'
                onClick={handleConfirmCode}
                className={`${styles.text} ${styles.button} ${styles.confirmButton}`}
              >
                {t("Confirm")}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} xs={12}>
              <p className={styles.labelText}>
                {t("HaventReceivedAnEmail")}&nbsp;
                <a
                  href='javascript:void(0);'
                  className={styles.labelText}
                  onClick={async () => await ChangeEmail(email)}
                >
                  {t("ResendCode")}
                </a>
              </p>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AccountConfirmationModal;
