import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import NavBar from "./components/navbar/NavBar";
import Footer from "./components/footer/Footer";
import LoginModal from "./components/modals/login/LoginModal";
// import SignupModal from "./components/modals/signup/SignupModal";

import PrivacyAndCookiePolicy from "./pages/privacy-and-cookie-policy/PrivacyAndCookiePolicy";
import TermsAndConditions from "./pages/terms-and-contitions/TermsAndConditions";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import FAQ from "./pages/faq/Faq";
import SafeAndFairPlay from "./pages/safe-and-fair-play/SafeAndFairPlay";
import Profile from "./pages/profile/Profile";
// import BuyDiamonds from "./pages/buy-diamonds/BuyDiamonds";
import ResponsibleGambling from "./pages/responsible-gambling/ResponsibleGambling";

import ForgotPasswordModal from "./components/modals/forgot-password/ForgotPasswordModal";
import RecoveryCodeModal from "./components/modals/recovery-code/RecoveryCodeModal";
import NewPasswordModal from "./components/modals/new-password/NewPasswordModal";

import AuthProvider from "./context/AuthProvider";
import UserProfileProvider from "./context/UserProfileProvider";
import AxiosProvider from "./context/AxiosProvider";

import Translation from "./translations";

import "./App.css";

function App() {
  const [isLoginModalVisible, setIsLoginModalVisible] =
    useState<boolean>(false);
  // const [isSignupModalVisible, setIsSignupModalVisible] =
  //   useState<boolean>(false);
  const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] =
    useState<boolean>(false);
  const [isRecoveryCodeModalVisible, setIsRecoveryCodeModalVisible] =
    useState<boolean>(false);
  const [isNewPasswordModalVisible, setIsNewPasswordModalVisible] =
    useState<boolean>(false);
  const [newPasswordConfirmationCode, setNewPasswordConfirmationCode] =
    useState<string>("");
  const [newPasswordEmail, setNewPasswordEmail] = useState<string>("");

  return (
    <>
      <Translation>
        <AuthProvider>
          <AxiosProvider>
            <UserProfileProvider>
              <NavBar
                handleSetIsLoginModalVisible={setIsLoginModalVisible}
                // handleSetIsSignupModalVisible={setIsSignupModalVisible}
              />
              <BrowserRouter>
                <Routes>
                  <Route path='/' element={<Home />}></Route>
                  <Route path='/about' element={<About />}></Route>
                  <Route path='/contact' element={<Contact />}></Route>
                  <Route
                    path='/privacy-and-cookie-policy'
                    element={<PrivacyAndCookiePolicy />}
                  ></Route>
                  <Route
                    path='/terms-and-conditions'
                    element={<TermsAndConditions />}
                  ></Route>
                  <Route path='/faq' element={<FAQ />}></Route>
                  <Route
                    path='/safe-and-fair-play'
                    element={<SafeAndFairPlay />}
                  ></Route>
                  <Route path='/profile' element={<Profile />}></Route>
                  {/* <Route path='/buy-diamonds' element={<BuyDiamonds />}>
                    <Route
                      path='/buy-diamonds/approved/*'
                      element={<BuyDiamonds />}
                    />
                    <Route
                      path='/buy-diamonds/delined/*'
                      element={<BuyDiamonds />}
                    />
                    <Route
                      path='/buy-diamonds/pending/*'
                      element={<BuyDiamonds />}
                    />
                  </Route> */}
                  <Route path='/responsible-gambling' element={<ResponsibleGambling />}></Route>
                </Routes>
              </BrowserRouter>
              <Footer />
              <LoginModal
                handleSetIsLoginModalVisible={setIsLoginModalVisible}
                isLoginModalVisible={isLoginModalVisible}
                handleSetIsForgotPasswordModalVisible={
                  setIsForgotPasswordModalVisible
                }
              />
              {/* <SignupModal
                handleSetIsSignupModalVisible={setIsSignupModalVisible}
                isSignupModalVisible={isSignupModalVisible}
                handleSetNewPasswordEmail={setNewPasswordEmail}
              /> */}
              <ForgotPasswordModal
                handleSetIsForgotPasswordModalVisible={
                  setIsForgotPasswordModalVisible
                }
                isForgotPasswordModalVisible={isForgotPasswordModalVisible}
                handleSetIsRecoveryCodeModalVisible={
                  setIsRecoveryCodeModalVisible
                }
                handleSetNewPasswordEmail={setNewPasswordEmail}
              />
              <RecoveryCodeModal
                handleSetIsRecoveryCodeModalVisible={
                  setIsRecoveryCodeModalVisible
                }
                isRecoveryCodeModalVisible={isRecoveryCodeModalVisible}
                handleSetIsNewPasswordModalModalVisible={
                  setIsNewPasswordModalVisible
                }
                handleSetNewPasswordConfirmationCode={
                  setNewPasswordConfirmationCode
                }
                newPasswordEmail={newPasswordEmail}
              />
              <NewPasswordModal
                handleSetIsNewPasswordModalModalVisible={
                  setIsNewPasswordModalVisible
                }
                isNewPasswordModalVisible={isNewPasswordModalVisible}
                newPasswordConfirmationCode={newPasswordConfirmationCode}
              />
            </UserProfileProvider>
          </AxiosProvider>
        </AuthProvider>
      </Translation>
    </>
  );
}

export default App;
