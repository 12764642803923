import React, { useState } from "react";
import { Row, Modal, Button  } from "react-bootstrap";

import _ from "lodash";

import { useTranslation } from "react-i18next";

import {
  appleProvider,
  auth,
  facebookProvider,
  googleProvider
} from "../../../firebase";
import { getWebParams } from "../../../helpers/functions/getWebParams";
import { getCountryAndLanguageCode } from "../../../helpers/functions/getCountryAndLanguageCode";

import styles from "./DisclaimerModal.module.css";


const DisclaimerModal: React.FC<{}> = ({}) => {
 
    const { t } = useTranslation();
    const [isDisclaimerModalVisible, setIsDisclaimerModalVisible] = useState(true);

    const handleIsDisclaimerModalVisible = () => {
      setIsDisclaimerModalVisible(isDisclaimerModalVisible => !isDisclaimerModalVisible);
    
    };

    const handleReject = () => {
      location.href = `https://www.google.com`;
    };

    return (
      <Modal
        className={styles.modal}
        show={isDisclaimerModalVisible}
        onHide={() => {
          handleIsDisclaimerModalVisible();
        }}
      >
        <Modal.Header closeButton>{t("DisclaimerModalHeader")}</Modal.Header>
        <Modal.Body>
            {t("DisclaimerModalBody")}
            <Row className={styles.buttonRow}>
              <Button
                    className={`${styles.text} ${styles.buttonOk} ${styles.mgBottom10}`}
                    onClick={handleIsDisclaimerModalVisible}
              >
                {t("IUnderstand")}
              </Button>
              <Button
                className={`${styles.text} ${styles.buttonReject} ${styles.mgBottom10}`}
                onClick={handleReject}
              >
                {t("GoBack")}
              </Button>
            </Row>
        </Modal.Body>
      </Modal>
    );
  };

export default DisclaimerModal;

