export const FEEDBACK_OPTIONS = [
  {
    id: 1,
    name: 'Suggestions',
  },
  {
    id: 2,
    name: 'Bug',
  },
  ,
  {
    id: 3,
    name: 'Cheating',
  },
  ,
  {
    id: 4,
    name: 'Payment',
  },
  ,
  {
    id: 5,
    name: 'Language',
  },
  {
    id: 6,
    name: 'Privacy',
  },
];
