import React from "react";
import axios, { AxiosResponse } from "axios";
import qs from "qs";
import { useAuth } from "../context/AuthProvider";
import { getWebParams } from "../helpers/functions/getWebParams";

import { AxiosContextData } from "../types/axios-context-data";
import { Props} from "../types/props";

const currentWebsiteUrl = window.location.href;
const baseUrl = currentWebsiteUrl.includes("dev")
   ? "https://dev.betnclubs.com/"
   : "https://prod.betnclubs.com/";

const axiosInstance = axios.create({ baseURL: baseUrl });
axiosInstance.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);
const userPreferedLang = "en";

let paymentBaseUrl = "https://secure.safecharge.com/ppp/purchase.do";
// process.env.NODE_ENV === "production"
//   ? "https://secure.safecharge.com/ppp/purchase.do"
//   : "https://ppp-test.safecharge.com/ppp/purchase.do";
const paymentAxiosInstance = axios.create({ baseURL: paymentBaseUrl });

export const AxiosContext = React.createContext<AxiosContextData>({
  UserSignUp: () => null,
  UserLogin: () => null,
  ForgotPassword: () => null,
  ForgotCompletePassword: () => null,
  ConfirmEmail: () => null,
  ChangeEmail: () => null,
  GetCurrentProfile: () => null,
  PostFeedback: () => null,
  GetDiamondsStore: () => null,
  RequestPaymentPage: () => null,
  RefreshToken: () => null,
  SocialLogin: () => null,
  UserGoogleLogin: () => null,
  UserFacebookLogin: () => null,
  UserAppleLogin: () => null
});
export const useAxios = () => React.useContext(AxiosContext);

export const AxiosProvider: React.FC<Props> = ({ children }) => {
  const { getAccessToken, storeToken } = useAuth();

  axios.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const handleTokenRefresh = async () => {
    const isLoggedIn = localStorage.getItem("token");
    if (!isLoggedIn) return;
    try {
      await RefreshToken();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      // if token expired
      if (error?.response?.status === 401) {
        //refresh the token
        return handleTokenRefresh().then(async () => {
          // get the new access token
          const newAccessToken = localStorage.getItem("token");
          // reset the authorization headers
          const newAuthorization = `Bearer ${newAccessToken}`;
          error.config.headers.Authorization = newAuthorization;
          // retry request
          return axios.request(error.config);
        });
      }
      return Promise.reject(error);
    }
  );

  const UserSignUp = async (payload: {
    username: string;
    email: string;
    password: string;
    ageConfirmed: boolean;
    eulaConfirmed: boolean;
  }) => {
    try {
      const headers = {
        headers: {
          "Content-type": "application/json"
        }
      };
      const params = getWebParams(payload, "SIGN_UP");
      const request = await axiosInstance.post(
        `api/profile/register`,
        params,
        headers
      );
      return request;
    } catch (error) {
      throw error;
    }
  };

  const UserLogin = async (payload: { username: string; password: string }) => {
    const headers = {
      headers: {
        "Content-type": "application/x-www-form-urlencoded"
      }
    };
    // console.log("will getWebParams()");
    const params = getWebParams(payload, "LOG_IN");
    // console.log(params);
    try {
      // console.log("Will axios");
      // console.log(qs.stringify(params));
      const request = await axiosInstance.post(
        `api/token`,
        qs.stringify(params),
        headers
      );
      return request;
    } catch (error) {
      throw error;
    }
  };

  const ForgotPassword = async (email: string) => {
    const headers = {
      headers: {
        "Content-type": "application/json"
      }
    };
    try {
      const request = await axiosInstance.post(
        `api/profile/forgot`,
        { email: email },
        headers
      );
      return request;
    } catch (error) {
      throw error;
    }
  };

  const ForgotCompletePassword = async (payload: {
    code: string;
    password: string;
    password_confirm: string;
  }) => {
    const headers = {
      headers: {
        "Content-type": "application/json"
      }
    };
    try {
      const request = await axiosInstance.post(
        `api/profile/forgot-complete`,
        payload,
        headers
      );
      return request;
    } catch (error) {
      throw error;
    }
  };

  const ConfirmEmail = async (otp: string) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        "Content-type": "application/json",
        "Accept-Language": userPreferedLang
      }
    };
    try {
      const request = await axiosInstance.post(
        `api/profile/confirm-email`,
        { otp },
        headers
      );
      return request;
    } catch (error) {
      throw error;
    }
  };

  const ChangeEmail = async (email: string) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        "Content-type": "application/json",
        "Accept-Language": userPreferedLang
      }
    };
    try {
      const request = await axiosInstance.post(
        `api/profile/change-email`,
        { email },
        headers
      );
      return request;
    } catch (error) {
      throw error;
    }
  };

  const GetCurrentProfile = async () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        "Accept-Language": userPreferedLang
      }
    };
    try {
      const request = await axiosInstance.get(`api/profile/current`, headers);
      return request;
    } catch (error) {
      throw error;
    }
  };

  const PostFeedback = async (payload: {
    name: string;
    text: string;
    type: number;
  }) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`
      }
    };
    try {
      const request = await axiosInstance.post(
        `api/feedback/`,
        payload,
        headers
      );
      return request;
    } catch (error) {
      throw error;
    }
  };

  const GetDiamondsStore = async () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`
      }
    };
    try {
      const request = await axiosInstance.get(
        `/api/project/diamonds-store?search=webitem`,
        headers
      );
      return request;
    } catch (error) {
      throw error;
    }
  };

  const RequestPaymentPage = async (transactionInput: string) => {
    try {
      const transaction_url = `${paymentBaseUrl}?${transactionInput}`;
      return transaction_url;
    } catch (error) {
      throw error;
    }
  };

  const RefreshToken = async () => {
    const refresh = localStorage.getItem("refresh");

    const headers = {
      headers: {
        Authorization: `Bearer ${refresh}`,
        "Content-type": "application/json"
      }
    };

    try {
      const request = await axiosInstance.post(`api/refresh`, null, headers);
      const token = request.data;
      storeToken(token);
      return request;
    } catch (error) {
      throw error;
    }
  };

  const SocialLogin = async (payload: any, social: string) => {
    const url = `api/auth/${social}`;
    const headers = {
      headers: {
        // "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json"
      }
    };
    let response;
    response = await axiosInstance.post(url, payload, headers);
    return response;
  };

  const UserGoogleLogin = async (payload: any) => {
    const response = await SocialLogin(payload, "google");
    const token = response.data;
    storeToken(token);
    return response;
  };

  const UserFacebookLogin = async (payload: any) => {
    const response = await SocialLogin(payload, "facebook");
    const token = response.data;
    storeToken(token);
    return response;
  };

  const UserAppleLogin = async (payload: any) => {
    const response = await SocialLogin(payload, "apple");
    const token = response.data;
    storeToken(token);
    return response;
  };

  const value: AxiosContextData = {
    UserSignUp,
    UserLogin,
    ForgotPassword,
    ForgotCompletePassword,
    ConfirmEmail,
    ChangeEmail,
    GetCurrentProfile,
    PostFeedback,
    GetDiamondsStore,
    RequestPaymentPage,
    RefreshToken,
    SocialLogin,
    UserGoogleLogin,
    UserFacebookLogin,
    UserAppleLogin
  };

  return (
    <AxiosContext.Provider value={value}>{children}</AxiosContext.Provider>
  );
};

export default AxiosProvider;
