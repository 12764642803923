import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import styles from "./Home.module.css";

import DisclaimerModal from "../../components/modals/disclaimer/DisclaimerModal";

import androidDownloadButton from "../../assets/androidDownloadButton.svg";
import appStoreDownloadButton from "../../assets/appStoreDownloadButton.svg";
import googlePlayDownloadButton from "../../assets/googlePlayDownloadButton.svg";
import youtubeLogo from "../../assets/yt_logo_betnclubs.png";

import ReactPlayer from 'react-player';

const Home: React.FC<{}> = () => {
  const { t } = useTranslation();

  const appleUrl = "https://apps.apple.com/app/id1539639522";
  const googleUrl =
    "https://play.google.com/store/apps/details?id=com.betn_clubs";
  const androidUrl =
    "https://drive.google.com/drive/folders/1LIw96zdlrbqOIstZHGMqNXp6KPY9Nqnd";

  return (
    <Container fluid className={`${styles.home} vh-100`}>
      <Row>
        <Col md={7} className={styles.leftCol}>
          <Row>
            <h1 className={styles.header1}>Bet'nClubs</h1>
          </Row>
          <Row>
            <h2 className={styles.header2}>{t("BettingService")}</h2>
          </Row>
          {/* <Row>
            <h3 className={styles.header3}>
              {t("DoYouEnjoySports")}?<br />
            </h3>
          </Row> */}
          {/* <Row>
            <h3 className={`${styles.header3} ${styles.header3Smaller}`}>
              {t("EvenMoreSoBettingOnSports")}?
            </h3>
          </Row> */}
          <Row>
            <Col>
            <p className={styles.descriptionText}>
              {/* {t("NowYouCanHaveTheSameThrill")} <strong>{t("NoRisks")}</strong>{" "}
              {t("AndFor")} <strong>{t("Free")}</strong>. */}
              {t("CreateBettingClubs")}
            </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={styles.descriptionText}>
                {/* {t("CreateBetting")}
                <strong>{t("Clubs")}</strong> {t("ForYourFriends")} */}
                {t("EnjoyRealWorld")}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={styles.descriptionText}>
                {/* {t("Enjoy")} <strong>{t("RealWorldOdds")}</strong>,{" "}
                {t("UpdatingInRealTime")} */}
                {t("CreateManageAndShareYourSuccess")}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <p
                    className={`${styles.downloadText} ${styles.uppercase} ${styles.fs30}`}
                  >
                    {t("GetTheApp")}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p
                    className={`${styles.downloadText} ${styles.uppercase} ${styles.fs15}`}
                  >
                    {t("OnYourFavoritePlatform")}
                  </p>
                </Col>
              </Row>
              <Row className={styles.downloadLinksContainer}>
                <Col md={4} sm={4} className={`${styles.link} ${styles.link1}`}>
                  <a href={appleUrl} target='_blank'>
                    <img
                      src={appStoreDownloadButton}
                      alt={t("AvailableOnTheAppStore")}
                      className={styles.downloadButton}
                    />
                  </a>
                </Col>
                <Col md={4} sm={4} className={`${styles.link} ${styles.link2}`}>
                  <a href={googleUrl} target='_blank'>
                    <img
                      src={googlePlayDownloadButton}
                      alt={"GetItOnGooglePlay"}
                      className={styles.downloadButton}
                    />
                  </a>
                </Col>
                <Col md={4} sm={4} className={`${styles.link} ${styles.link3}`}>
                  <a href={androidUrl} target='_blank'>
                    <img
                      src={androidDownloadButton}
                      alt={"DownloadForAndroid"}
                      className={styles.downloadButton}
                    />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={styles.extraRow}>
        <Col md={7} className={styles.extraRowFirstColumn}>
          <a
            href='https://www.youtube.com/@betnclubs'
            target='_blank'
            >
              <p className={styles.tutorialsTextbox}>
                {t('tutorialsOn')}&nbsp;
                <img src={youtubeLogo} className={styles.ytLogo} alt="YouTube"></img>
              </p>    
          </a>
        </Col>
      </Row>
      {/*<Row className={styles.extraRow}>
        <Col md={7} className={styles.extraRowFirstColumn}>
          <ReactPlayer width="100%" height="330px" url={'https://www.youtube.com/watch?v=0O8-1agIFhg'} playing={false} />
        </Col>
        </Row>*/}
      <DisclaimerModal></DisclaimerModal>

    </Container>
  );
};

export default Home;
