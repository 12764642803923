import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import styles from "./SafeAndFairPlay.module.css";

const SafeAndFairPlay: React.FC<{}> = () => {
  const { t } = useTranslation();

  const disruptiveBehaviourRef = useRef<null | HTMLDivElement>(null);
  const useOfThirdPartySoftwareRef = useRef<null | HTMLDivElement>(null);
  const unauthorizedGemBuyingRef = useRef<null | HTMLDivElement>(null);
  const buyingSellingSharingRef = useRef<null | HTMLDivElement>(null);
  const otherUnacceptableBehaviourRef = useRef<null | HTMLDivElement>(null);

  const executeScroll = (ref: React.MutableRefObject<HTMLDivElement | null>) =>
    ref?.current?.scrollIntoView(true);

  return (
    <Container fluid className={styles.safeAndFairPlay}>
      <Row  className={`${styles.parentRow}`}>
        <Col xl={3} lg={3} className={`d-lg-block d-none ${styles.leftCol}`}>
          <Row>
            <h3
              className={`${styles.header1} ${styles.header3} ${styles.mgBottom30}`}
            >
              <a onClick={() => executeScroll(disruptiveBehaviourRef)}>
                {t("DisruptiveBehaviour")}
              </a>
            </h3>
          </Row>
          <Row>
            <h3
              className={`${styles.header1} ${styles.header3} ${styles.mgBottom30}`}
            >
              <a onClick={() => executeScroll(useOfThirdPartySoftwareRef)}>
                {t("UseOfThirdPartySoftware")}
              </a>
            </h3>
          </Row>
          <Row>
            <h3
              className={`${styles.header1} ${styles.header3} ${styles.mgBottom30}`}
            >
              <a onClick={() => executeScroll(unauthorizedGemBuyingRef)}>
                {t("UnauthorizedGemBuying")}
              </a>
            </h3>
          </Row>
          <Row>
            <h3
              className={`${styles.header1} ${styles.header3} ${styles.mgBottom30}`}
            >
              <a onClick={() => executeScroll(buyingSellingSharingRef)}>
                {t("BuyingSellingSharing")}
              </a>
            </h3>
          </Row>
          <Row>
            <h3 className={`${styles.header1} ${styles.header3}`}>
              <a onClick={() => executeScroll(otherUnacceptableBehaviourRef)}>
                {t("OtherUnacceptableBehaviour")}
              </a>
            </h3>
          </Row>
        </Col>
        <Col xl={9} lg={9} md={9} sm={12} xs={12}>
          <Row>
            <h2 className={`${styles.header1} ${styles.header2}`}>
              {t("SafeAndFairPlay")}
            </h2>
          </Row>
          <Row>
            <p className={styles.answerText}>{t("SafeAndFairPlayAnswer1")}</p>
            <br></br>
            <br></br>
            <p className={styles.answerText}>{t("SafeAndFairPlayAnswer2")}</p>
            <br></br>
            <br></br>
            <p className={styles.answerText}>{t("SafeAndFairPlayAnswer3")}</p>
          </Row>
          <Row ref={disruptiveBehaviourRef} className={styles.padScroll}>
            <h2
              className={`${styles.header1} ${styles.header2} ${styles.mgTop30}`}
            >
              {t("DisruptiveBehaviour")}
            </h2>
          </Row>
          <Row>
            <p className={styles.answerText}>
              {t("DisruptiveBehaviourAnswer1")}
            </p>
            <br></br>
            <br></br>
            <p className={styles.answerText}>
              {t("DisruptiveBehaviourAnswer2")}
            </p>
            <br></br>
            <br></br>
            <p className={styles.answerText}>
              {t("DisruptiveBehaviourAnswer3")}
            </p>
            <br></br>
            <br></br>
            <p className={styles.answerText}>
              {t("DisruptiveBehaviourAnswer4")}
            </p>
            <br></br>
            <br></br>
            <p className={styles.answerText}>
              {t("DisruptiveBehaviourAnswer5")}
            </p>
            <br></br>
            <br></br>
            <p className={styles.answerText}>
              {t("DisruptiveBehaviourAnswer6")}
            </p>
            <br></br>
            <br></br>
            <p className={styles.answerText}>
              {t("DisruptiveBehaviourAnswer7")}
            </p>
            <br></br>
            <br></br>
            <p className={styles.answerText}>
              {t("DisruptiveBehaviourAnswer8")}
            </p>
            <br></br>
            <br></br>
            <p className={styles.answerText}>
              {t("DisruptiveBehaviourAnswer9")}
            </p>
            <br></br>
            <br></br>
            <p className={styles.answerText}>
              {t("DisruptiveBehaviourAnswer10")}
            </p>
          </Row>
          <Row  ref={useOfThirdPartySoftwareRef} className={styles.padScroll}>
            <Col>
              <Row>
                <h2
                  className={`${styles.header1} ${styles.header2}  ${styles.mgTop30}`}
                >
                  {t("UseOfThirdPartySoftware")}
                </h2>
              </Row>
              <Row>
                <p className={styles.answerText}>
                  {t("UseOfThirdPartySoftwareAnswer1")}
                </p>
                <br></br>
                <br></br>
                <p className={styles.answerText}>
                  {t("UseOfThirdPartySoftwareAnswer2")}
                </p>
                <br></br>
                <br></br>
                <p className={styles.answerText}>
                  {t("UseOfThirdPartySoftwareAnswer3")}
                </p>
                <br></br>
                <br></br>
                <p className={styles.answerText}>
                  {t("UseOfThirdPartySoftwareAnswer4")}
                </p>
                <br></br>
                <br></br>
                <p className={styles.answerText}>
                  {t("UseOfThirdPartySoftwareAnswer5")}
                </p>
                <br></br>
                <br></br>
                <p className={styles.answerText}>
                  {t("UseOfThirdPartySoftwareAnswer6")}
                </p>
              </Row>
            </Col>
          </Row>
          <Row ref={unauthorizedGemBuyingRef} className={styles.padScroll}>
            <Col>
              <Row>
                <h2
                  className={`${styles.header1} ${styles.header2}  ${styles.mgTop30}`}
                >
                  {t("UnauthorizedGemBuying")}
                </h2>
              </Row>
              <Row>
                <p className={styles.answerText}>
                  {t("UnauthorizedGemBuyingAnswer1")}
                </p>
                <br></br>
                <br></br>
                <p className={styles.answerText}>
                  {t("UnauthorizedGemBuyingAnswer2")}
                </p>
                <br></br>
                <br></br>
                <p className={styles.answerText}>
                  {t("UnauthorizedGemBuyingAnswer3")}
                </p>
                <br></br>
                <br></br>
                <p className={styles.answerText}>
                  {t("UnauthorizedGemBuyingAnswer4")}
                </p>
              </Row>
            </Col>
          </Row>
          <Row ref={buyingSellingSharingRef} className={styles.padScroll}>
            <Col>
              <Row>
                <h2
                  className={`${styles.header1} ${styles.header2}  ${styles.mgTop30}`}
                >
                  {t("BuyingSellingSharing")}
                </h2>
              </Row>
              <Row>
                <p className={styles.answerText}>
                  {t("BuyingSellingSharingAnswer1")}
                </p>
                <br></br>
                <br></br>
                <p className={styles.answerText}>
                  {t("BuyingSellingSharingAnswer2")}
                </p>
              </Row>
            </Col>
          </Row>
          <Row ref={otherUnacceptableBehaviourRef} className={styles.padScroll}>
            <Col>
              <Row>
                <h2
                  className={`${styles.header1} ${styles.header2}  ${styles.mgTop30}`}
                >
                  {t("OtherUnacceptableBehaviour")}
                </h2>
              </Row>
              <Row>
                <p className={styles.answerText}>
                  {t("OtherUnacceptableBehaviourAnswer1")}
                </p>
                <br></br>
                <br></br>
                <p className={styles.answerText}>
                  {t("OtherUnacceptableBehaviourAnswer2")}
                </p>
                <br></br>
                <br></br>
                <p className={styles.answerText}>
                  {t("OtherUnacceptableBehaviourAnswer3")}
                </p>
                <br></br>
                <br></br>
                <p className={styles.answerText}>
                  {t("OtherUnacceptableBehaviourAnswer4")}
                </p>
                <br></br>
                <br></br>
                <p className={styles.answerText}>
                  {t("OtherUnacceptableBehaviourAnswer5")}
                </p>
                <br></br>
                <br></br>
                <p className={styles.answerText}>
                  {t("OtherUnacceptableBehaviourAnswer6")}
                </p>
                <br></br>
                <br></br>
                <p className={styles.answerText}>
                  {t("OtherUnacceptableBehaviourAnswer7")}
                </p>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SafeAndFairPlay;
