export const ODDS_FORMATS = [
  {
    id: 'DC',
    name: 'Decimal',
  },
  {
    id: 'AM',
    name: 'American',
  },
  {
    id: 'HK',
    name: 'Hong Kong',
  },
];
