import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import styles from "./TermsAndConditions.module.css";

const TermsAndConditions: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className={styles.termsAndContitions}>
      <Row>
        <Col xl={3} lg={3} md={3} sm={12} xs={12}>
          <h1 className={styles.header1}>{t("Terms&Conditions")}</h1>
        </Col>
        <Col xl={9} lg={9} md={9} sm={12} xs={12}>
          <p className={styles.text}>
            Cresmart Advisor is the legal entity that oversees the development
            and operation of BetnClubs. <br></br>
            <br></br>For purposes of these Terms of Service, Cresmart Advisor
            shall mean Cresmart Advisor, Ltd., its partners, parent companies,
            subsidiaries, licensees, licensors and affiliates. (collectively,
            also referred to herein as “Company”, “we”, “our” or “us”) <br></br>
            <br></br>THESE TERMS OF SERVICE (“TERMS” OR “AGREEMENT”) CONSTITUTE
            A LEGAL AGREEMENT BETWEEN YOU (“YOU” OR “YOUR”) AND Cresmart Advisor
            LIMITED (AS DEFINED ABOVE). BY DOWNLOADING, AND USING INSTALLING ANY
            OF OUR SOFTWARE AND/OR APPLICATIONS (“SOFTWARE” OR “APPLICATION”) IN
            CONNECTION WITH SERVICES PROVIDED BY (THE SERVICES, THE SOFTWARE AND
            THE APPLICATION COLLECTIVELY, THE “SERVICE”). YOU AGREE THAT YOU
            HAVE READ, UNDERSTOOD, ACCEPT AND AGREE TO BE BOUND BY THESE TERMS.
            IF YOU DO NOT AGREE TO THESE TERMS, DO NOT ACCESS OR OTHERWISE USE
            THE SERVICE. <br></br>
            <br></br>In addition to the terms and conditions of this Agreement,
            please review our Privacy Policy [Privacy Policy]. By using the
            Service, you are accepting the terms and conditions of our Privacy
            Policy. If you do not agree to your information being used in any of
            the ways described in the Privacy Policy, do not access or otherwise
            use the Service. <br></br>
            <br></br>
            <h5>Changes to the Service and/or Terms:</h5>**IMPORTANT NOTE: **
            Cresmart Advisor reserves the right, in its sole discretion, to
            modify or change the terms of this Agreement at any time by posting
            the changes on or within the Software or other parts of the Service.
            Your continued use of the Service following the posting of such
            changes constitutes your acceptance of the revised Agreement.
            Cresmart Advisor, Ltd. may use reasonable commercial efforts to
            provide notice of material changes to you. If the modified Agreement
            is not acceptable to you, your sole recourse is to discontinue your
            use of the Service. You agree that Cresmart Advisor may change any
            part of the Service, including its content, at any time or
            discontinue the Service or any part thereof, for any reason, without
            notice to you and without liability. <br></br>
            <br></br>
            <br></br>
            <h4>1. Definitions</h4>
            
            <br></br>“Account” means an account you create when you access the
            Services. <br></br>
            <br></br>“Community Rules” means the rules of conduct that govern
            your interaction with our Services and other players, which can be
            found here. <br></br>
            <br></br>“Feature Terms” means any other rules related to specific
            services, like platforms and APIs, applications for mobile devices,
            forums, contests, subscriptions or loyalty programs that we may
            publish, which apply to your use of those specific services and
            state they are part of these Terms. <br></br>
            <br></br>“Services” means our games, products, services, content,
            Cresmart Advisor, and/or other domains or websites operated by
            Cresmart Advisor. <br></br>
            <br></br>“Terms of Service” or “Terms” means these terms of service.
            <br></br>
            <br></br>“User Content” means all the data that you upload,
            transmit, create, or generate on or through the Services. This
            includes things like your profile picture, your in-game text or
            video chat, and your in-game art or other content authored or
            designed by you. <br></br>
            <br></br>“Virtual Items” means (a) virtual currency, including
            without limitation virtual coins, tokens, or points, all for use in
            the Services and (b) virtual in-game items. <br></br>
            <br></br>“Cresmart Advisor Corporate Family” means Cresmart
            Advisor’s subsidiaries, parent companies, joint ventures, and other
            corporate entities under common ownership and their agents,
            consultants, employees, officers, and directors. <br></br>
            <br></br>“Cresmart Advisor Affiliates” refers to the Cresmart
            Advisor Corporate Family plus Cresmart Advisor’s and the Cresmart
            Advisor Corporate Family’s third-party content providers,
            distributors, licensees, or licensors.
            <br></br>
            <br></br>
            <br></br>
            <h4>1. Eligibility Criteria</h4> <br></br>
            <h5>1.1 Age requirements:</h5>
            To use the Service, you must be a natural person, at least
            the age of majority in the state in which you live, and who meets
            other eligibility criteria set forth in these Terms. If you are
            under the legal age mentioned above, you must immediately
            discontinue use and make no further use of the Service, and delete
            the Software or Application. <br></br>
            <br></br>
            <h5>
              1.2 You Must Reside In A Jurisdiction That Does Not Prohibit Use
              of the Service:
            </h5>
            The Service is intended only for users who are not
            prohibited by the laws of any applicable jurisdiction from using
            such Service. The Company does not intend to enable you to
            contravene applicable law. You represent, warrant, and agree to
            ensure that your use of the Software and the Service will comply
            with all applicable laws, statutes and regulations. The Company
            shall not be responsible for any illegal or unauthorized use of the
            Software and/or the Service by you. <br></br>
            <br></br>
  	<h5>1.3 NO REAL MONEY GAMBLING OR PRIZES</h5>
            You understand that our Service does not offer real world gambling nor does it offer
real world prizes.
            <br></br>
            <h5>1.4 Failure to Comply:</h5>
            At our sole discretion, we may require proof that you meet
            the conditions and eligibility criteria set out in these Terms.
            Failure to comply will result in the closing of your Account and the
            loss of all Virtual Items (each as defined below) accumulated
            through your use of the Service.
            <br></br>
            <br></br>
            <br></br>
            <h4>2. Your Account. </h4>
            
            <h5>
              2.1 Grant of Access: Subject to these Terms, the Service allows
              you, where permitted by applicable law to:
            </h5>
            (1) register to play free-to-play games;<br></br>
            <br></br>(2) access other content and services provided by Cresmart
            Advisor, Ltd. <br></br>
            <br></br>
            <h5>2.2 Registration:</h5>
            We require that you register in order to participate in the
            Service, including the free-to-play games. You must also provide a
            valid email account and complete the verification for that email
            account that we send you. You hereby consent to receiving an email
            verification to your email account by providing Cresmart Advisor,
            Ltd. with your email for the purposes of registering on our Service
            and participating in our features. Through this process, you will
            establish your user account (“Account”). In the event that the
            activation or confirmation process is not completed, Cresmart
            Advisor, Ltd. reserves the right to suspend all activities on the
            Account until the account details are verified. <br></br>
            <br></br>
            <br></br>
            <h5>2.3 Confidentiality of Account Information:</h5>
            If you choose to create an Account with us, you must treat
            your user name, password or any other piece of information related
            to your Account as confidential, and you must not disclose it to any
            other person or entity. You also acknowledge that your Account is
            personal to you and agree not to provide any other person with
            access to the Service, including the App, or portions thereof using
            your user name, password or other security information. You are
            responsible for all activity performed and transactions entered into
            on or through your Account, and you agree that we may treat any
            activity performed or transaction entered into on or through your
            Account as authorized by you. You agree to notify us immediately of
            any unauthorized access to or use of your user name or password or
            any other breach of security. You also agree to ensure that you exit
            from your Account at the end of each session.
            <br></br>
            <br></br>
            <br></br>
            <h5>2.4 Right to Disable Account and Account Details:</h5>
            We have the right to disable any user name, password or
            other identifier, whether chosen by you or provided by us, at any
            time in our sole discretion for any or no reason, including if, in
            our opinion, you have violated any provision of these Terms.
            <br></br>
            <br></br>
            <br></br>
            <h5>2.5 Right to Suspend, Modify, Remove or Add to the Service:</h5>
            
            We reserve the right to suspend, modify, remove or add to the
            Service in its sole discretion with immediate effect and without
            notice. The Company shall not be liable for any loss suffered by you
            resulting from any changes made and you shall have no claims against
            the Company in such regard. <br></br>
            <br></br>If you do not agree to any of the provisions of the User
            Agreement you should immediately stop using the Software and remove
            the Software from your computer and/or any other applicable device.
            <br></br>
            <br></br>
            <br></br>
            <h4>3. Grant of License/Intellectual Property. </h4>
            
            <h5>3.1 Limited License Grant:</h5>
            Subject to the terms and conditions contained herein
            Cresmart Advisor grants the User non-exclusive, personal,
            non-transferable, non-sublicensable, revocable right to install and
            use the Software on your Device in order to access Cresmart Advisor
            servers. <br></br>
            <br></br>
            <h5>3.2 License Grant Limitations:</h5>
            The Software is licensed to you by Cresmart Advisor for
            your private personal use. Please note that the Service is not for
            use by individuals under the legal age mentioned in point 1.1., or
            connecting to the Software or servers from jurisdictions from which
            it is illegal to do so. <br></br>
            <br></br>
            <h5>3.3 License Grant Prohibitions:</h5>
            You may not, within the limits prescribed by applicable
            laws: <br></br>
            <br></br>a. copy, distribute, publish, reverse engineer, decompile,
            disassemble, modify, or translate the Software or make any attempt
            to access the source code to create derivate works of the source
            code of the Software, or otherwise; <br></br>
            <br></br>b. sell, assign, sublicense, transfer, distribute or lease
            the Software; <br></br>
            <br></br>c. make the Software available to any third party through a
            computer network or otherwise; <br></br>
            <br></br>d. export the Software to any country (whether by physical
            or electronic means); or <br></br>
            <br></br>e. use the Software in a manner prohibited by applicable
            laws or regulations, (each of the above is an "Unauthorized Use").
            <br></br>
            <br></br>
            <h5>
              3.4: You warrant that any names or images used by you in
              connection with your Account in connection with the Service (for
              example, your user name) shall not infringe the intellectual
              property, privacy, or other rights of any third party. You hereby
              grant Cresmart Advisor, Ltd. a worldwide, irrevocable,
              transferable, royalty-free, sublicensable license to use such
              names and images for any purpose connected with the Services,
              subject to the terms of this Agreement and the terms of the
              Privacy Policy.
            </h5>
            
            <h5>
              3.5 Notice and Procedure for Making Claims under the Digital
              Millennium Copyright Act:
            </h5>
            The Digital Millennium Copyright Act (DMCA) provides
            recourse to copyright owners who believe that their rights under the
            United States Copyright Act have been infringed by acts of third
            parties over the Internet. If an individual believes that their
            copyrighted work has been copied without their authorization and is
            available within the Service in a way that may constitute copyright
            infringement, he/she may provide notice of their claim to our
            Company. For notice to be effective, it must include the following
            information: <br></br>
            <br></br>A physical or electronic signature of a person authorized
            to act on behalf of the owner of an exclusive right that is
            allegedly infringed; <br></br>
            <br></br>A description of the copyrighted work that you claim has
            been infringed; <br></br>
            <br></br>A description of where the allegedly infringing material is
            located within the Service; <br></br>
            <br></br>Information reasonably sufficient to permit our Company to
            contact the complaining party, such as address, telephone number,
            and, if available, an e-mail address at which the complaining party
            may be contacted; <br></br>
            <br></br>A statement that you have a good-faith belief that the
            disputed use is not authorized by the copyright owner, its agent, or
            the law; and <br></br>
            <br></br>A statement that the information in the notification is
            accurate and under penalty of perjury, that the complaining party is
            authorized to act on behalf of the owner of an exclusive right that
            is allegedly infringed. <br></br>
            <br></br>
            <br></br>
            <h5>Trademarks</h5>
            We own or are in the process of registering trademarks for
            our many goods and services, including, without limitation,
            betngroups and the associated graphics, logos and service marks and
            may not be used without prior written consent of our Company. All
            other trademarks, product names, and company names and logos
            appearing within the Service are the property of their respective
            owners. <br></br>
            <br></br>
            <br></br>
            <h5>Rights of Publicity</h5>
            
            <h5>3.6 Publicity:</h5>
            By participating in the Service, You agree to the use by
            our Company of your username, photograph(s), likeness, statements,
            biographical information, voice and city and state address for
            advertising and promotional purposes of the Service, including,
            without limitation, worldwide, and in perpetuity, in any and all
            forms of media, now known or hereafter devised without compensation,
            review or approval rights, notification or permission, except where
            prohibited by law. Our Company reserves the right to make public
            statements about the entrants and winner(s), on the Internet, in
            promotional materials or otherwise. You agree that we may announce
            any winner’s name on the Service at any time in connection with the
            marketing and promotion of the Service or of our Company. <br></br>
            <br></br>
            <h5>3.7 Ideas and Inventions:</h5>
            All comments, feedback, suggestions, ideas, and other
            submissions (“Ideas”) disclosed, submitted, or offered to our
            Company in connection with your use of the Service shall be the
            exclusive property of our Company. You agree that unless otherwise
            prohibited by law we may use, sell, exploit and disclose the Ideas
            in any manner, without restriction and without compensation to You.
            <br></br>
            <br></br>
            <h4>4. VIRTUAL ITEMS.</h4>
            
            4.1 While using the Service, you may “buy” or “purchase” virtual
            items (e.g. “diamonds” or “Chips” for use in the Service (“Virtual
            Items")Any “Virtual Items” balance shown in your Account does not
            constitute a real-world balance or reflect any stored value, but
            instead constitutes a measurement of the extent of your license.{" "}
            <br></br>
            4.2 You do not own any Virtual Items that you obtained through our
            Services, regardless of whether you “earned” those Virtual Items or
            “purchased” them. Your Account and any related Virtual Items are
            owned by The Company. <br></br>
	4.3. The Company does not offer any winnings that can be reflected in 
real world goods with or without economical value, nor any offers, excursions or
prizes as such.
            <br></br>Cresmart Advisor, Ltd. gives you a limited license and
            right to use your Account and the related Virtual Items while we
            offer the Service. <br></br>
            <br></br>4.4 You are not allowed to transfer Virtual Items outside
            of the Services (e.g., in the “real world”), for example by selling,
            gifting, or trading them. You are not allowed to sublicense, trade,
            sell, or attempt to sell Virtual Items for “real” money, or exchange
            Virtual Items for value of any kind outside of a game. Any such
            behaviors constitute a breach of this Agreement and Cresmart
            Advisor, Ltd. is entitled to take immediate action if the Company
            believes you are in violation of this provision, including account
            closure. <br></br>
            <br></br>
            <h4>5. Prohibited Uses. </h4>
            
            <h5>
              5.1 Software Modifications/Manipulation of Vulnerabilities:
            </h5>{" "}
            You may not attempt to modify, decompile, reverse-engineer or
            disassemble the Software in any way. You may not exploit
            vulnerability or glitches to your advantage in your use of the
            Service. Further, you may not directly or indirectly disable,
            circumvent, or otherwise interfere with the operation of software
            designed to detect or prevent cheating. <br></br>
            <br></br>
            <h5>5.2 Collusion:</h5> Collusion between you and other users of the
            Service by sharing game related information while playing in the
            same club or by any other methods is strictly forbidden. You may not
            collude with another user who is using unauthorized software,
            hardware, or modifications to obtain an advantage over other users
            of the Service. The Company reserves the right to consider any
            collusion or an attempt at collusion between users as a breach of
            this Agreement and terminate the user account(s) and restrict
            his/her further access to the Service, and other Company products.
            <br></br>
            <br></br>
            <h5>5.3 Automatic Player (Bots):</h5> The use of artificial
            intelligence including, without limitation, “robots” or “bots” is
            strictly forbidden in connection with the Service. All actions taken
            in relation to the Service by a User must be executed personally by
            players through the user interface accessible by use of the
            Software, and without the assistance of any form of artificial
            intelligence. <br></br>
            <br></br>
            <h5>5.4 Fraudulent Behaviour:</h5> In the event that The Company.
            deems that you have engaged or attempted to engage in fraudulent,
            unlawful, dishonest or improper activity while using the Service,
            including without limitation, engaging in any of the activities set
            forth here under “Prohibited Uses” or any other game manipulation,
            Cresmart Advisor, Ltd. reserves the right to take action against
            you, including to block you from access to the Service, to terminate
            your Account along with blocking the possibility of future access to
            Company Services, and to report you to governmental or other
            authorities. <br></br>
            <br></br>
            <h5>5.5 Accessing Other Player Accounts:</h5> You shall access the
            Software and use the Services only via your own account(s) and you
            may never access the Software or use the Services by means of
            another person’s account. Should you attempt to use the Services by
            means of any other person’s account, we will be entitled to
            immediately close any and all of your accounts. <br></br>
            <br></br>
            <h5>
              5.6 In case of account closure due to a prohibited use, as
              outlined in this section, you are prohibited from creating new
              accounts. Any future attempt to create an account after being
              banned from the Service will be considered a breach of this
              Agreement and will result in the closure of such accounts.
            </h5>{" "}
            <br></br>
            <br></br>
            <h4>6. NO WARRANTIES</h4> <br></br>
            <h5>6.1 DISCLAIMER OF WARRANTIES:</h5>
            THE SERVICE (INCLUDING THE APPLICATION) AND ALL CONTENT
            THEREON OR THEREIN ARE PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY
            KIND, EITHER EXPRESS, IMPLIED OR STATUTORY. WITHOUT LIMITING THE
            FOREGOING, Cresmart Advisor LIMITED, OUR PARTNERS, AND OUR AND THEIR
            RESPECTIVE AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES,
            AGENTS AND LICENSORS (COLLECTIVELY, THE “COMPANY PARTIES”)
            EXPLICITLY DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY
            WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. THE
            COMPANY PARTIES MAKE NO WARRANTY THAT THE SERVICE WILL MEET YOUR
            REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR
            ERROR-FREE BASIS. THE COMPANY PARTIES MAKE NO WARRANTY REGARDING THE
            QUALITY OF ANY PRODUCTS, SERVICES OR CONTENT OBTAINED THROUGH THE
            SERVICE OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR
            RELIABILITY OF ANY CONTENT OBTAINED THROUGH SERVICE. <br></br>
            <br></br>YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS
            AND INTERACTIONS WITH OTHER USERS OF THE SERVICE AND WITH OTHER
            PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR
            USE OF THE SERVICE. YOU UNDERSTAND THAT Cresmart Advisor LIMITED.
            DOES NOT SCREEN OR INQUIRE INTO THE BACKGROUND OF ANY USERS OF THE
            SERVICE, NOR DOES Cresmart Advisor LIMITED MAKE ANY ATTEMPT TO
            VERIFY THE STATEMENTS OF USERS OF THE SERVICE. THE COMPANY PARTIES
            MAKE NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS OF
            THE SERVICE OR THEIR COMPATIBILITY WITH ANY CURRENT OR FUTURE USERS
            OF THE SERVICE. YOU AGREE TO TAKE REASONABLE PRECAUTIONS IN ALL
            COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SERVICE AND
            WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT
            OF YOUR USE OF THE SERVICE, PARTICULARLY IF YOU DECIDE TO MEET
            OFFLINE OR IN PERSON. SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER
            OF IMPLIED TERMS IN CONTRACTS WITH CONSUMERS AND AS A RESULT THE
            DISCLAIMERS OF THIS SECTION. <br></br>
            <br></br>
            <h5>6.2 Right to Suspend/Discontinue/Modify the Service:</h5>
            The Company reserves the right to suspend, discontinue,
            modify, remove or add to the Service in its absolute discretion with
            immediate effect and without an obligation to provide you with
            notice where we consider it necessary to do so.
            <br></br>
            <br></br>
            <h5>6.3 Updates to the service/maintenance:</h5>
            You acknowledge and agree that Cresmart Advisor may update
            the Service with or without notifying you. Cresmart Advisor may
            require that you accept updates to the Service and you may also need
            to update third party software from time to time in order to receive
            the Service. Cresmart Advisor conducts maintenance work on its
            system from time to time. A portion, or sometimes all, of the
            features of the Service may not be available during maintenance
            periods. All problems encountered during the use of the Service,
            including those with regard to Your Account, can be reported to
            Cresmart Advisor. when the problem is encountered by contacting
            support@betnclubs.com. <br></br>
            <br></br>
            <br></br>
            <h4>
              7. Malfunctionalities <br></br>
            </h4>
            Cresmart Advisor Ltd will not be liable for errors coming
            from third party services (eg. incorrect odds, suspended markets,
            incorrect scores etc.), for failure in servers, informatic issues,
            etc. In these malfunctioning cases, Cresmart Advisor Ltd reserves
            the right to cancel any bet/wager at any time. In case a bet/wager
            was cancelled the amount of chips will be returned to the user’s
            balance. <br></br>
            <br></br>
            <br></br>
            <h4>8. Users Contributions</h4>
            
            <h5>8.1 General Terms:</h5>The Service may contain message boards,
            forums, bulletin boards and other interactive features
            (collectively, “Interactive Services”) that allow users to post,
            submit, publish, display or transmit to other users or other persons
            (hereinafter, “post”) messages, content or materials (collectively,
            “User Contributions”) on or through the Service. <br></br>
            <br></br>Any User Contribution you post to the Service will be
            considered non-confidential and non-proprietary. By providing any
            User Contribution on the Service, you grant us and our affiliates
            and service providers, and each of their and our respective
            licensees, successors and assigns the right to use, reproduce,
            modify, perform, display, distribute and otherwise disclose to third
            parties any such material for any purpose. <br></br>
            <br></br>You understand and acknowledge that you are responsible for
            any User Contributions you submit or contribute, and you, not
            Cresmart Advisor, Ltd., have full responsibility for such content,
            including its legality, reliability, accuracy and appropriateness.
            We are not responsible, or liable to any third party, for the
            content or accuracy of any User Contributions posted by you or any
            other user of the Service. <br></br>
            <br></br>We have the right to: (i) remove or refuse to post any User
            Contributions for any or no reason in our sole discretion; (ii) take
            any action with respect to any User Contribution that we deem
            necessary or appropriate in our sole discretion, including but not
            limited to if we believe that such User Contribution violates the
            Terms of Use, infringes any intellectual property right or other
            right of any person or entity, threatens the personal safety of
            users of the Service, or the public, or could create liability for
            the Company; (iii) disclose your identity or other information about
            you or your account to any third party who claims that material
            posted by you violates their rights, including their intellectual
            property rights or their right to privacy; (iv) disclose your
            identity or other information about you or your account to any
            governmental, law enforcement, or regulatory authority, or in
            response to legal process such as a subpoena <br></br>
            <br></br>However, we do not undertake to review all material before
            it is posted on the Service, and cannot ensure prompt removal of
            objectionable material after it has been posted. Accordingly, we
            assume no liability for any action or inaction regarding
            transmissions, communications or content provided by any user or
            third party. We have no liability or responsibility to anyone for
            performance or non-performance of the activities described in this
            section. <br></br>
            <br></br>
            <h5>8.2 Content Standards:</h5>
            User Contributions must in their entirety comply with all
            applicable federal, state, local, and international laws and
            regulations. Without limiting the foregoing, User Contributions must
            not: <br></br>
            <br></br>∙Contain any material that is defamatory, obscene,
            indecent, abusive, offensive, harassing, violent, hateful,
            inflammatory, or otherwise objectionable or unlawful. <br></br>
            <br></br>∙Promote sexually explicit or pornographic material,
            violence, or discrimination based on race, sex, religion,
            nationality, disability, sexual orientation, or age. <br></br>
            <br></br>∙Infringe any patent, trademark, trade secret, copyright,
            or other intellectual property or other rights of any other person.
            <br></br>
            <br></br>∙Violate the legal rights (including the rights of
            publicity and privacy) of others or contain any material that could
            give rise to any civil or criminal liability under applicable laws
            or regulations or that otherwise may be in conflict with these Terms
            of Service and our Privacy Policy [Privacy Policy] <br></br>
            <br></br>∙Be likely to deceive any person. <br></br>
            <br></br>∙Promote any illegal activity, or advocate, promote, or
            assist any unlawful act. <br></br>
            <br></br>∙Cause annoyance, inconvenience, or needless anxiety or be
            likely to upset, embarrass, alarm, or annoy any other person.
            <br></br>
            <br></br>∙Impersonate any person, or misrepresent your identity or
            affiliation with any person or organization. <br></br>
            <br></br>∙Involve commercial activities or sales, such as contests,
            sweepstakes, and other sales promotions, barter, or advertising.
            <br></br>
            <br></br>∙Give the impression that they emanate from or are endorsed
            by us or any other person or entity, if this is not the case.
            <br></br>
            <br></br>
            <br></br>
            <h5>8.3 Reliance on Information Posted:</h5>
            The information presented on or through the Service is made
            available solely for general information purposes. We do not warrant
            the accuracy, completeness or usefulness of this information. Any
            reliance you place on such information is strictly at your own risk.
            We disclaim all liability and responsibility arising from any
            reliance placed on such materials by you or any other visitor to the
            Service, or by anyone who may be informed of any of its contents.
            <br></br>
            <br></br>
            <br></br>
            <h4>9. Dispute Resolution and Arbitration.</h4>
            
            <h5>9.1 Purpose:</h5>
            This Dispute Resolution and Arbitration provision (this
            “Provision”) facilitates the prompt and efficient resolution of any
            disputes that may arise between you and Cresmart Advisor Limited.
            Arbitration is a form of private dispute resolution in which persons
            with a dispute waive their rights to file a lawsuit, to proceed in
            court and to a jury trial, and instead submit their disputes to a
            neutral third person (or arbitrator) for a binding decision. You
            have the right to opt-out of this Provision (as explained below),
            which means you would retain your right to litigate your disputes in
            a court, either before a judge or jury. Please read this Provision
            carefully. It provides that all Disputes between you and Cresmart
            Advisor Limited. (as defined below, for this Provision) shall be
            resolved by binding arbitration. Arbitration replaces the right to
            go to court. In the absence of this arbitration agreement, you may
            otherwise have a right or opportunity to bring claims in court,
            before a judge or jury, and/or participate in or be represented in a
            case filed in court by others (including, but not limited to, class
            actions). Except as otherwise provided, entering into this agreement
            constitutes a waiver of your right to litigate claims and all
            opportunity to be heard by a judge or jury. There is no judge or
            jury in arbitration, and court review of an arbitration award is
            limited. The arbitrator must follow this agreement and can award the
            same damages and relief as a court (including attorneys’ fees). For
            the purpose of this Provision, Cresmart Advisor means Cresmart
            Advisor Limited. and its parents, subsidiary, and affiliate
            companies, and each of their respective officers, directors,
            employees, and agents. The term “Dispute” means any dispute, claim,
            or controversy between you and the Company regarding any aspect of
            your relationship with the Company, whether based in contract,
            statute, regulation, ordinance, tort (including, but not limited to,
            fraud, misrepresentation, fraudulent inducement, or negligence), or
            any other legal or equitable theory, and includes the validity,
            enforceability or scope of this Provision (with the exception of the
            enforceability of the Class Action Waiver clause below). “Dispute”
            is to be given the broadest possible meaning that will be enforced.
            YOU AND Cresmart Advisor LIMITED EACH AGREE THAT, EXCEPT AS PROVIDED
            BELOW, ANY AND ALL DISPUTES, AS DEFINED ABOVE, WHETHER PRESENTLY IN
            EXISTENCE OR BASED ON ACTS OR OMISSIONS IN THE PAST OR IN THE
            FUTURE, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING
            ARBITRATION RATHER THAN IN COURT IN ACCORDANCE WITH THIS PROVISION.
            <br></br>
            <br></br>
            <h5>9.2 Pre-Arbitration Claim Resolution:</h5>
            For all Disputes, whether pursued in court or arbitration,
            you must first give Cresmart Advisor Limited an opportunity to
            resolve the Dispute. You must commence this process by written
            notification to the Cresmart Advisor Limited at
            contact@betnclubs.com. That written notification must include (1)
            your name, (2) your address, (3) a written description of your
            Claim, and (4) a description of the specific relief you seek. If
            Cresmart Advisor Limited does not resolve the Dispute within 45 days
            after it receives your written notification, you may pursue your
            Dispute in arbitration. You may pursue your Dispute in a court only
            under the circumstances described below. <br></br>
            <br></br>
            <h5>9.3 Exclusions from Arbitration/Right to Opt Out:</h5>
            Notwithstanding the above, you or Cresmart Advisor, Ltd.
            may choose to pursue a Dispute in court and not by arbitration. TO
            OPT-OUT OF THESE ARBITRATION PROCEDURES YOU MUST DO SO WITHIN 30
            DAYS FROM THE DATE THAT YOU FIRST CONSENT TO THIS AGREEMENT (the
            “Opt-Out Deadline”). You may opt out of this Provision by mailing
            written notification to Cresmart Advisor Limited at
            legal@betnclubs.com. Your written notification must include (1) your
            name, (2) your address, and (3) a clear statement that you do not
            wish to resolve disputes with Cresmart Advisor Limited through
            arbitration. Your decision to opt-out of this Provision will have no
            adverse effect on your relationship with Cresmart Advisor Limited.
            <h5>
              Any opt-out request received after the Opt-Out Deadline will not
              be valid and you must pursue your Dispute in arbitration or small
              claims court.
            </h5>
            
            <h5>9.4 Arbitration Procedures:</h5>
            If this Provision applies and the Dispute is not resolved
            as provided above (“Pre-Arbitration Claim Resolution”) either you or
            Cresmart Advisor Limited may initiate arbitration proceedings. The
            International Centre For Dispute Resolution (“ICDR”) will arbitrate
            all Disputes, and the arbitration will be conducted before a single
            arbitrator. The arbitration shall be commenced as an individual
            arbitration, and shall in no event be commenced as a class
            arbitration. All issues shall be for the arbitrator to decide,
            including the scope of this Provision. <br></br>
            <br></br>Arbitration Award—The arbitrator may award on an individual
            basis any relief that would be available pursuant to applicable law,
            and will not have the power to award relief to, against or for the
            benefit of any person who is not a party to the proceeding. The
            arbitrator will make any award in writing but need not provide a
            statement of reasons unless requested by a party. Such award will be
            final and binding on the parties, except for any right of appeal
            provided by the ICDR, and may be entered in any court having
            jurisdiction over the parties for purposes of enforcement. <br></br>
            <br></br>Location of Arbitration – Any arbitration whether initiated
            by you or Cresmart Advisor Limited shall be initiated in
            Cluj-Napoca, Romania. <br></br>
            <br></br>Payment of Arbitration Fees and Costs – Each Party shall
            pay its own arbitration filing fees and arbitrator’s costs and
            expenses. You are responsible for all fees and costs that you incur
            in the arbitration, including, but not limited to, attorneys or
            expert witnesses. Fees and costs may be awarded as provided pursuant
            to applicable law. <br></br>
            <br></br>
            <h5>9.5 Class Action Waiver:</h5>
            Except as otherwise provided in this Provision, the
            arbitrator may not consolidate more than one person’s claims, and
            may not otherwise preside over any form of a class or representative
            proceeding or claims (such as a class action, consolidated action or
            private attorney general action) unless both you and Cresmart
            Advisor Limited specifically agree to do so following initiation of
            the arbitration. If you choose to pursue your Dispute in court by
            opting out of this Provision, as specified above, this Class Action
            Waiver will not apply to you. Neither you, nor any other user of the
            Service can be a class representative, class member, or otherwise
            participate in a class, consolidated, or representative proceeding
            without having complied with the opt-out requirements above.
            <br></br>
            <br></br>
            <h5>9.6 Jury Waiver:</h5>
            You understand and agree that by entering into this
            agreement you and Cresmart Advisor Limited are each waiving the
            right to a jury trial or a trial before a judge in a public court.
            In the absence of this Provision, you and Cresmart Advisor Limited
            might otherwise have had a right or opportunity to bring Disputes in
            a court, before a judge or jury, and/or to participate or be
            represented in a case filed in court by others (including class
            actions). Except as otherwise provided below, those rights are
            waived. Other rights that you would have if you went to court, such
            as the right to appeal and to certain types of discovery, may be
            more limited or may also be waived. <br></br>
            <br></br>
            <h5>9.7 Severability:</h5>
            If any clause within this Provision (other than the Class
            Action Waiver clause above) is found to be illegal or unenforceable,
            that clause will be severed from this Provision, and the remainder
            of this Provision will be given full force and effect. If the Class
            Action Waiver clause is found to be illegal or unenforceable, this
            entire Provision will be unenforceable and the Dispute will be
            decided by a court. <br></br>
            <br></br>
            <h5>9.8 Continuation:</h5>
            This Provision shall survive the termination of your
            service with Cresmart Advisor Limited. <br></br>
            <br></br>
            <br></br>
            <h4>10. Limitation of Liability</h4>
            YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT
            PERMITTED BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND
            USE OF THE SERVICE, INCLUDING ANY SOFTWARE APPLICATION, REMAINS WITH
            YOU. NEITHER THE COMPANY PARTIES NOR ANY OTHER PARTY INVOLVED IN
            CREATING, PRODUCING, OR DELIVERING THE SERVICE WILL BE LIABLE FOR
            ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES,
            INCLUDING LOST PROFITS, LOSS OF DATA, LOSS OF GOODWILL, SERVICE
            INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE, THE COST OF
            SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR
            BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION
            WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICE,
            OR FROM ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER
            USERS OF THE SERVICE OR OTHER PERSONS WITH WHOM YOU COMMUNICATE OR
            INTERACT AS A RESULT OF YOUR USE OF THE SERVICE, WHETHER BASED ON
            BREACH OF WARRANTY, BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE),
            PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE
            COMPANY PARTIES HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH
            DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE
            FAILED OF ITS ESSENTIAL PURPOSE. IN NO EVENT WILL THE COMPANY
            PARTIES’ AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH
            THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES, ANY
            PART THEREOF, OR ANY CONTENT EXCEED FIVE HUNDRED DOLLARS ($500). THE
            LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF
            THE BASIS OF THE BARGAIN BETWEEN CRESMART ADVISOR LIMITED AND YOU.
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
            LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, AND IF SO THEN
            THE ABOVE LIMITATION MAY NOT APPLY TO YOU. <br></br>
            <br></br>
            <br></br>
            <h4>11. Indemnity</h4>
            
            <br></br>You agree to indemnify, save, and hold the Company Parties
            harmless from any claims, losses, damages, liabilities, including
            legal fees and expenses, arising out of your use or misuse of the
            Service, any violation by you of these terms, or any breach of the
            representations, warranties, and covenants made by you herein.
            Cresmart Advisor Limited reserves the right, at your expense, to
            assume the exclusive defense and control of any matter for which you
            are required to indemnify Cresmart Advisor Limited, and You agree to
            cooperate with Cresmart Advisor Limited’s defense of these claims.
            Cresmart Advisor Limited will use reasonable efforts to notify you
            of any such claim, action, or proceeding upon becoming aware of it.
            You agree that the provisions in this Indemnity section will survive
            any termination of your Account (if applicable) or of your access to
            or use of the Service. <br></br>
            <br></br>
            <br></br>
            <h4>12.Additional Mobile Application Terms.</h4> <br></br>
            <h5>12.1 Apple Disclaimers:</h5> The following additional terms and
            conditions apply with respect to any application that Cresmart
            Advisor Limited (the “COMPANY”) provides to you designed for use on
            an Apple iOS-powered mobile device (an “iOS App”): <br></br>
            <br></br>• You acknowledge that these Terms are between you and
            COMPANY only, and not with Apple, Inc. (“Apple”). <br></br>
            <br></br>• Your use of the iOS App must comply with Usage Rules set
            forth in Apple’s then-current App Store Terms of Service. <br></br>
            <br></br>• COMPANY, and not Apple, is solely responsible for our iOS
            App and the services and content available thereon. You acknowledge
            that Apple has no obligation to provide maintenance and support
            services with respect to our iOS App. To the maximum extent
            permitted by applicable law, Apple will have no warranty obligation
            whatsoever with respect to our iOS App and any other claims, losses,
            liabilities, damages, costs or expenses attributable to any failure
            of the iOS App to conform to any warranty. <br></br>
            <br></br>• You agree that COMPANY, and not Apple, is responsible for
            addressing any claims by you or any third party relating to our iOS
            App or your possession and/or use of our iOS App, including, but not
            limited to: (i) product liability claims; (ii) any claim that the
            iOS App fails to conform to any applicable legal or regulatory
            requirement; and (iii) claims arising under consumer protection or
            similar legislation, and all such claims are governed solely by
            these Terms and any law applicable to us as provider of the iOS App.
            <br></br>
            <br></br>• You agree that COMPANY, and not Apple, shall be
            responsible, to the extent required by these Terms, for the
            investigation, defense, settlement and discharge of any third-party
            intellectual property infringement claim related to our iOS App or
            your possession and use of our iOS App. <br></br>
            <br></br>• You represent and warrant that (i) you are not located in
            a country that is subject to a U.S. Government embargo, or that has
            been designated by the U.S. Government as a “terrorist supporting”
            country; and (ii) You are not listed on any U.S. Government list of
            prohibited or restricted parties. <br></br>
            <br></br>• You agree to comply with all applicable third-party terms
            of agreement when using our iOS App (e.g., you must not be in
            violation of your wireless data service terms of agreement when
            using the iOS App). <br></br>
            <br></br>• You agree that Apple and Apple’s subsidiaries are third
            party beneficiaries to these Terms as they relate to your license to
            use the iOS App. Upon your acceptance of these Terms, Apple will
            have the right (and will be deemed to have accepted the right) to
            enforce these Terms against you as they relate to your license of
            the iOS App as a third-party beneficiary thereof. <br></br>
            <br></br>
            <h5>12.2 Google Disclaimers:</h5>
            The following additional terms and conditions apply with
            respect to any application that COMPANY provides to you designed for
            use on an Android-powered mobile device (an “Android App”):
            <br></br>
            <br></br>• You acknowledge that these Terms are between you and
            COMPANY only, and not with Google, Inc. (“Google”). <br></br>
            <br></br>• Your use of the Android App must comply with Google’s
            then-current Google Play Terms of Service. <br></br>
            <br></br>• COMPANY, and not Google, is solely responsible for the
            Android App, the services and content available thereon and the
            support and maintenance thereof. Google has no obligation or
            liability to you with respect to the Android App or these Terms.
            <br></br>
            <br></br>
            <br></br>
            <h4>13. Governing Law</h4>
            The Agreement and any matters relating here to shall be
            governed by, and construed in accordance with, the laws of Romania.
            Except as provided in the “Dispute Resolution and Arbitration”
            <br></br>
            <br></br>Provision (above), the exclusive jurisdiction and venue of
            any action with respect to the subject matter of this Agreement and
            any claim dispute or difference concerning the Agreement and any
            matter arising thereunder, under all circumstances, including if you
            opt-out of the Arbitration Provision, will be the courts located in
            Romania and each of the parties hereto waives any objection to
            jurisdiction and venue in such courts. <br></br>
            <br></br>
            <h4>14. Severability</h4>
            If a provision of this Agreement is or becomes illegal,
            invalid or unenforceable in any jurisdiction, that shall not affect
            the validity or enforceability in that jurisdiction of any other
            provision hereof or the validity or enforceability in other
            jurisdictions of that or any other provision hereof. <br></br>
            <br></br>
            <h4>15. Assignment</h4>
            Cresmart Advisor Limited reserves the right to assign this
            agreement, in whole or in part, at any time without notice. The User
            may not assign any of his/her rights or obligations under this
            Agreement <br></br>
            <br></br>
            <h4>16. Legal Text</h4>
            The English language version of this Agreement shall be the
            prevailing version in the event of any discrepancy between any
            translated versions of this Agreement.
            <br></br>
            <br></br>
            <h4>17. Entire Agreement</h4>
            These Terms constitute the entire and exclusive
            understanding and agreement between Cresmart Advisor Limited. and
            you regarding the Service, and these Terms supersede and replace any
            and all prior oral or written understandings or agreements between
            Cresmart Advisor Limited and you regarding the Service. <br></br>
            <br></br>
            <br></br>
            <h4>18. Contact Information</h4>
            If you have any questions regarding these Terms of Use or
            any other matter, you can contact Cresmart Advisor, Ltd. at:
            care@betnclubs.com.
            <br></br>
            <br></br>
            <h4>19. Account deletion</h4>
            You can delete your account by logging in the app and in the "Profile" menu under "My Account" you have the option to delete your account. All personal information related data will be deleted (username, e-mail etc).
            <br></br>
            Additionally you can login in our web and send us a request in the "Contact" form https://betnclubs.com/contact by selecting "Privacy" for category and a message asking for account deletion. All personal information related data will be deleted (username, e-mail etc).
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsAndConditions;
