import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Modal, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { browserName } from "react-device-detect";

import _ from "lodash";

import { useAxios } from "../../../context/AxiosProvider";

import styles from "./NewPasswordModal.module.css";

const NewPasswordModal: React.FC<{
  isNewPasswordModalVisible: boolean;
  handleSetIsNewPasswordModalModalVisible: (value: boolean) => void;
  newPasswordConfirmationCode: string;
}> = ({
  isNewPasswordModalVisible,
  handleSetIsNewPasswordModalModalVisible,
  newPasswordConfirmationCode
}) => {
  const { t } = useTranslation();

  const [validated, setValidated] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [newPasswordType, setNewPasswordType] = useState<string>("password");
  const [confirmPasswordType, setConfirmPasswordType] =
    useState<string>("password");

  const { ForgotCompletePassword } = useAxios();

  useEffect(() => {
    setValidated(false);
    setIsError(false);
  }, []);

  const handleConfirmPassword = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    } else {
      event.preventDefault();
      if (!_.isEmpty(newPassword) && !_.isEmpty(confirmPassword)) {
        try {
          await ForgotCompletePassword({
            code: newPasswordConfirmationCode,
            password: newPassword!,
            password_confirm: confirmPassword!
          });
          setNewPassword("");
          setConfirmPassword("");
          handleSetIsNewPasswordModalModalVisible(false);
        } catch (error) {
          setIsError(true);
          setErrorMessage(t("EnsurePasswordNoCharacters"));
          setValidated(false);
        }
      }
      setValidated(true);
    }
    if (newPassword === confirmPassword) setValidated(true);
    else {
      setErrorMessage(t("PasswordsDoNotMatch"));
      setValidated(false);
      setIsError(true);
    }
  };

  const togglePassword = (passwordType: string) => {
    if (passwordType === "confirm")
      setConfirmPasswordType(
        confirmPasswordType === "password" ? "text" : "password"
      );
    else
      setNewPasswordType(newPasswordType === "password" ? "text" : "password");
  };

  return (
    <Modal
      show={isNewPasswordModalVisible}
      onHide={() => {
        handleSetIsNewPasswordModalModalVisible(false);
        setNewPassword("");
        setConfirmPassword("");
        setValidated(false);
        setIsError(false);
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleConfirmPassword} noValidate validated={validated}>
          <Form.Group>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <Form.Label className={styles.labelText}>
                  {t("NewPassword")}
                </Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputGroup>
                  <Form.Control
                    type={newPasswordType}
                    required
                    value={newPassword}
                    onChange={(event) => {
                      if (
                        event.target.value !== confirmPassword &&
                        !_.isEmpty(confirmPassword)
                      ) {
                        setIsError(true);
                        setErrorMessage(t("PasswordsDoNotMatch"));
                      } else setIsError(false);
                      setNewPassword(event.target.value);
                    }}
                  />
                  <a
                    className={
                      browserName === "Safari"
                        ? isError
                          ? `${styles.eyeIcon} ${styles.right60}`
                          : `${styles.eyeIcon} ${styles.right35}`
                        : `${styles.eyeIcon}`
                    }
                    onClick={() => togglePassword("new")}
                  >
                    {newPasswordType === "password" ? (
                      <IoEyeOutline size={28} />
                    ) : (
                      <IoEyeOffOutline size={28} />
                    )}
                  </a>
                </InputGroup>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <Form.Label className={`${styles.labelText} ${styles.mgTop20}`}>
                  {t("ConfirmPassword")}
                </Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputGroup>
                  <Form.Control
                    type={confirmPasswordType}
                    isInvalid={confirmPassword !== newPassword}
                    required
                    value={confirmPassword}
                    onChange={(event) => {
                      if (
                        event.target.value !== newPassword &&
                        !_.isEmpty(newPassword)
                      ) {
                        setIsError(true);
                        setErrorMessage(t("PasswordsDoNotMatch"));
                      } else setIsError(false);
                      setConfirmPassword(event.target.value);
                    }}
                  />
                  <a
                    className={
                      browserName === "Safari"
                        ? isError || validated
                          ? `${styles.eyeIcon} ${styles.right60}`
                          : `${styles.eyeIcon} ${styles.right35}`
                        : `${styles.eyeIcon}`
                    }
                    onClick={() => togglePassword("confirm")}
                  >
                    {confirmPasswordType === "password" ? (
                      <IoEyeOutline size={28} />
                    ) : (
                      <IoEyeOffOutline size={28} />
                    )}
                  </a>
                </InputGroup>
              </Col>
            </Row>
          </Form.Group>
          <Row className={styles.errorMessageContainer}>
            <Col lg={12} md={12} xs={12}>
              <p className={isError ? styles.errorMessage : styles.displayNone}>
                {errorMessage}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} xs={12}>
              <Button
                type='submit'
                onClick={handleConfirmPassword}
                className={`${styles.text} ${styles.button} ${styles.confirmButton}`}
              >
                {t("Confirm").toLowerCase()}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NewPasswordModal;
