export const getWebParams = (
  payload: {
    username: string;
    password?: string;
    ageConfirmed?: boolean;
    eulaConfirmed?: boolean;
  },
  type: string
) => {
  const params = {
    password: payload.password,
    unique_id: "web_" + payload.username!,
    os_type: "and",
    token: "unknown",
    device_type: "pc",
    brand: "pc",
    is_tablet: "false",
    is_location_enabled: "false",
    ip: "0.0.0.0",
    system_version: "web",
  };
  if (type === "SIGN_UP")
    return {
      ...params,
      username: payload.username,
      age_confirmed: payload.ageConfirmed,
      eula: payload.eulaConfirmed,
    };
  return { ...params, username: payload.username };
};
