import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import appPreview1 from "../../assets/appPreview1.png";
import appPreview2 from "../../assets/appPreview2.png";
import appPreview3 from "../../assets/appPreview3.png";
import appPreview4 from "../../assets/appPreview4.png";

import styles from "./About.module.css";

const About: React.FC<{}> = () => {
  const { t } = useTranslation();
  const joinText = t("JoinBetnClubs");
  const [left, center, right] = joinText.split(/<b>|<\/b>/);

  return (
    <Container fluid className={styles.about}>
      <Row>
        <Col className={`d-lg-block d-none ${styles.leftCol}`}>
          <img
            src={appPreview1}
            alt='app screenshot'
            className={styles.appPreview}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12} className={styles.rightCol}>
          <h2 className={`${styles.header1} ${styles.header2}`}>
            {t("AboutUs")}
          </h2>
          <br></br>
          <h3 className={`${styles.text} ${styles.header3}`}>
            {t("AboutText1")}
          </h3>
          <br></br>
          <p className={`${styles.text}`}>{t("AboutText2")}</p>
          <Col className='d-md-block d-lg-none'>
            <img
              src={appPreview1}
              alt='app screenshot'
              className={styles.appPreview}
            />
          </Col>
          <br></br>
          <br></br>
          <p className={`${styles.text}`}>{t("AboutText3")}</p>
          {/* <br></br>
          <br></br>
          <p className={`${styles.text}`}>{t("AboutText4")}</p> */}
          <br></br>
          <br></br>
          <Col className='d-md-block d-lg-none'>
            <img
              src={appPreview2}
              alt='app screenshot'
              className={styles.appPreview}
            />
          </Col>
        </Col>
      </Row>
      <Row>
        <Col className={`d-lg-block d-md-none ${styles.leftCol}`}>
          <img
            src={appPreview2}
            alt='app screenshot'
            className={styles.appPreview}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12} className={styles.rightCol}>
          <p className={`${styles.text}`}>{t("AboutText5")}</p>
        </Col>
        <Col className='d-md-block d-lg-none'>
          <img
            src={appPreview3}
            alt='app screenshot'
            className={styles.appPreview}
          />
        </Col>
      </Row>
      <Row>
        <Col className={`d-lg-block d-md-none ${styles.leftCol}`}>
          <img
            src={appPreview3}
            alt='app screenshot'
            className={styles.appPreview}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12} className={styles.rightCol}>
          <p className={`${styles.text}`}>{t("AboutText6")}</p>
        </Col>
      </Row>
      <Row>
        <Col className={`d-lg-block  ${styles.leftCol}`}>
          <img
            src={appPreview4}
            alt='app screenshot'
            className={styles.appPreview}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12} className={styles.rightCol}>
          <p className={styles.text}>{t("AboutText7")}</p>
          <p className={styles.text}>{t("AboutText8")}</p>
          <p className={styles.text}>∙ {t("AboutText9")}</p>
          <p className={styles.text}>∙ {t("AboutText10")}</p>
          <p className={styles.text}>∙ {t("AboutText11")}</p>
          <p className={styles.text}>∙ {t("AboutText12")}</p>
        </Col>
      </Row>
      <Row className={styles.bottomContainer}>
        <h1 className={`${styles.text} ${styles.header1}`}>
          {t("YourClub")}. {t("YourRules")}
        </h1>
        <p className={`${styles.text} ${styles.bottomText}`}>
          {left}
          <b className={styles.boldText}>{center}</b>
          {right}
        </p>
      </Row>
    </Container>
  );
};

export default About;
