import axios from "axios";

export const getCountryAndLanguageCode = async () => {
  try {
    const request = await axios.get("https://ipapi.co/json/");
    const countryCode = request.data.country_code;
    const languageCode = request.data.languages.split(",")[0];
    return { countryCode, languageCode };
  } catch (error) {
    throw error;
  }
};
