import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  NavDropdown,
  Button,
  Dropdown,
} from "react-bootstrap";
import { IoPersonCircleOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";

import Hamburger from "hamburger-react";

import { useAuth } from "../../context/AuthProvider";
import { useUserProfile } from "../../context/UserProfileProvider";

import appIcon from "../../assets/appIcon.svg";
import englishFlag from "../../assets/englishFlag.png";
import spanishFlag from "../../assets/spanishFlag.png";
import romanianFlag from "../../assets/romanianFlag.png";

import * as data from "./links.json";

import styles from "./NavBar.module.css";

const linksString = JSON.stringify(data);
const links = JSON.parse(linksString).links;
type Link = {
  label: string;
  href: string;
};

const NavBar: React.FC<{
  handleSetIsLoginModalVisible: (value: boolean) => void;
  // handleSetIsSignupModalVisible: (value: boolean) => void;
}> = ({
  handleSetIsLoginModalVisible,
  // handleSetIsSignupModalVisible
}) => {
  const [isOpen, setOpen] = useState(false);
  const { authToken, logOut } = useAuth();
  const { currentUser } = useUserProfile();
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState<string>();
  const [languageFlag, setLanguageFlag] = useState<string>();
  const pathname = window.location.pathname.replace("/", "");

  useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage);
      localStorage.setItem("lang", currentLanguage);
      switch (currentLanguage) {
        case "en": {
          setLanguageFlag(englishFlag);
          return;
        }
        case "es": {
          setLanguageFlag(spanishFlag);
          return;
        }
        case "ro": {
          setLanguageFlag(romanianFlag);
          return;
        }
        default:
          setLanguageFlag(englishFlag);
          return;
      }
    }
  }, [currentLanguage]);

  useEffect(() => {
    const language = localStorage.getItem("lang");
    if (language) setCurrentLanguage(language);
    else if (currentUser) setCurrentLanguage(currentUser?.language);
    else setCurrentLanguage("en");
  }, []);

  const handleLogOut = async () => {
    logOut();
  };

  return (
    <Container fluid>
      <Row className={styles.navbar}>
        <Col lg={6} md={8} sm={6} xs={6} className={styles.leftContainer}>
          <Navbar expand='lg'>
            <Navbar.Toggle className={styles.burgerIcon}>
              <Hamburger color='#ecbb40' toggled={isOpen} toggle={setOpen} />
            </Navbar.Toggle>
            <Navbar.Collapse>
              <Nav>
                {links.map((link: Link, index: number) => {
                  return (
                    <Nav.Link
                      href={`${link.href}`}
                      key={link.href}
                      className={
                        link.label === "Language" && !isOpen
                          ? styles.displayNone
                          : index === links?.length - 1
                          ? `${styles.link} ${styles.padding2}`
                          : !isOpen
                          ? link.label === "Home"
                            ? pathname === link.label.toLowerCase()
                              ? `${styles.activeLink} ${styles.link} ${styles.mgRight10} ${styles.padding1}`
                              : `${styles.link} ${styles.mgRight10} ${styles.padding1}`
                            : pathname === link.label.toLowerCase()
                            ? `${styles.activeLink} ${styles.link} ${styles.mgRight10} ${styles.padding2}`
                            : `${styles.link} ${styles.mgRight10} ${styles.padding2}`
                          : `${styles.link} ${styles.borderBottom} `
                      }
                    >
                      {link.label === "Support" ? (
                        <NavDropdown
                          title={t("Support")}
                          className={`${styles.link} ${styles.dropdown}`}
                        >
                          <NavDropdown.Item href='/safe-and-fair-play'>
                            {t("Safe&FairPlay")}
                          </NavDropdown.Item>
                          <NavDropdown.Item href='/faq'>
                            {t("FAQ")}
                          </NavDropdown.Item>
                          <NavDropdown.Item href='/contact'>
                            {t("Contact")}
                          </NavDropdown.Item>
                        </NavDropdown>
                      ) : link.label === "Language" ? (
                        isOpen ? (
                          <NavDropdown
                            title={t("Language")}
                            className={`${styles.link} ${styles.dropdown}`}
                          >
                            <NavDropdown.Item
                              onClick={() => {
                                setCurrentLanguage("en");
                              }}
                            >
                              <img
                                src={englishFlag}
                                alt='language flag'
                                className={`${styles.img} ${styles.img15}`}
                              />
                              {t("English")}
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              onClick={() => {
                                setCurrentLanguage("es");
                              }}
                            >
                              <img
                                src={spanishFlag}
                                alt='language flag'
                                className={`${styles.img} ${styles.img15}`}
                              />
                              {t("Spanish")}
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              onClick={() => {
                                setCurrentLanguage("ro");
                              }}
                            >
                              <img
                                src={romanianFlag}
                                alt='language flag'
                                className={`${styles.img} ${styles.img15}`}
                              />
                              {t("Romanian")}
                            </NavDropdown.Item>
                          </NavDropdown>
                        ) : (
                          <></>
                        )
                      ) : link.label === "Home" ? (
                        <div className={styles.appNameContainer}>
                          <img src={appIcon} className={styles.appIcon}></img>
                          <span className={styles.appNameText}>Bet'nClubs</span>
                        </div>
                      ) : (
                        t(link.label)
                      )}
                    </Nav.Link>
                  );
                })}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
        <Col
          className={`d-md-flex ${styles.rightContainer} ${
            isOpen ? styles.centeredRightContainer : ""
          }`}
          lg={6}
          md={4}
          sm={6}
          xs={6}
        >
          <Row className={styles.alignCenter}>
            {!authToken ? (
              <Col lg={4} md={4} sm={12}>
                <Button
                  onClick={() => handleSetIsLoginModalVisible(true)}
                  className={` ${styles.rightLink}
        ${styles.button} ${styles.outlineButton}`}
                >
                  {t("LogIn")}
                </Button>
                {/* <Button
                  onClick={() => handleSetIsSignupModalVisible(true)}
                  className={` ${styles.rightLink}
        ${styles.button} ${styles.filledButton} ${styles.mgRight10}`}
                >
                  {t("SignUp")}
                </Button> */}
              </Col>
            ) : (
              <Col lg={4} md={4}>
                <Dropdown>
                  <Dropdown.Toggle className={styles.accountIcon}>
                    <div>
                      <IoPersonCircleOutline size={30}></IoPersonCircleOutline>
                      <p className={styles.balanceText}>
                        {(currentUser?.balance ?? 0)
                          .toFixed(2)
                          .replace(/\./g, ",")}
                      </p>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={styles.profileDropdown}>
                    {/* <Dropdown.Item
                      className={`${styles.uppercase} ${styles.rightDropdownLink}`}
                      href={"/buy-diamonds"}
                    > 
                      {t("BuyDiamonds")}
                    </Dropdown.Item>*/}
                    <Dropdown.Item
                      className={`${styles.uppercase} ${styles.rightDropdownLink}`}
                      href={"/profile"}
                    >
                      {t("Profile")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        handleLogOut();
                      }}
                      className={`${styles.uppercase} ${styles.greyText}`}
                    >
                      {t("LogOut")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            )}

            <Col
              lg={4}
              md={4}
              className={`d-none d-md-flex ${styles.alignCenter}`}
            >
              <Dropdown>
                <Dropdown.Toggle
                  className={`${styles.rightLink}
        ${styles.language}  ${styles.hideLanguageDropdown}`}
                >
                  <img
                    src={languageFlag}
                    alt='language flag'
                    className={`${styles.img} ${styles.img28} ${styles.mgTop8}`}
                  />
                  {currentLanguage ? t(currentLanguage) : "English"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className={styles.uppercase}
                    onClick={() => {
                      setCurrentLanguage("en");
                    }}
                  >
                    <img
                      src={englishFlag}
                      alt='language flag'
                      className={`${styles.img} ${styles.img20}`}
                    />
                    {t("English")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={styles.uppercase}
                    onClick={() => {
                      setCurrentLanguage("es");
                    }}
                  >
                    <img
                      src={spanishFlag}
                      alt='language flag'
                      className={`${styles.img} ${styles.img20}`}
                    />
                    {t("Spanish")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={styles.uppercase}
                    onClick={() => {
                      setCurrentLanguage("ro");
                    }}
                  >
                    <img
                      src={romanianFlag}
                      alt='language flag'
                      className={`${styles.img} ${styles.img20}`}
                    />
                    {t("Romanian")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default NavBar;
