import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import styles from "./PrivacyAndCookiePolicy.module.css";

const PrivacyAndCookiePolicy: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className={styles.privacyAndCookiePolicy}>
      <Row>
        <Col xl={3} lg={3} md={3} sm={12} xs={12}>
          <h1 className={styles.header1}>{t("PrivacyPolicy")}</h1>
        </Col>
        <Col xl={9} lg={9} md={9} sm={12} xs={12}>
          <p className={styles.text}>
            Welcome to BetnClubs® (“BetnClubs,” “we,” “us” or “our”). BetnClubs
            provides a fast, beautiful and fun way for you to enjoy our content
            and services, and share media through our content-sharing platform.
            We offer a competitive sports game, to a brand-new level. <br></br>
            <br></br>In pursuit of our mission, we collect and use certain
            information from our players. Being transparent about our use of
            players’ information and respecting their privacy is incredibly
            important to BetnClubs. <br></br>
            <br></br>This Privacy Policy applies when you use our mobile
            services, web site, and any software provided on or in connection
            with BetnClubs services (collectively, the “Service”), and your
            choices about the collection and use of your information. Please
            read it before using BetnClubs’ Services because it will tell you
            how we and some of the companies we work with collect, store, use,
            and disclose your personal information when providing these Services
            to you. <br></br>
            <br></br>By using our Service you understand and agree that we are
            providing a platform for you to post content, including photos,
            comments and other materials you choose to make public and that are
            not strictly necessary for the Service (“User Content”), to the
            Service and to share User Content publicly. This means that other
            Users may search for, see, use, or share any of your User Content
            that you make publicly available through the Service, consistent
            with the terms and conditions of this Privacy Policy and our Terms
            of Use (which can be found at TERMS). <br></br>
            <br></br>If you have questions, comments, or concerns regarding our
            Privacy Policy or practices, please Contact Us. <br></br>
            <br></br>Our Policy applies to all visitors, users, and others who
            access the Service (“Users”).<br></br> <br></br>
            Click on the links below to jump to each section of this Policy:
            <br></br>
            <br></br>
            <a href='#informationWeCollect'>1. Information We Collect </a>
            <br></br>
            <a href='#howWeUseYourInformation'>
              2. How We Use Your Information <br></br>
            </a>
            <a href='#sharingOfYourInformation'>
              3. Sharing of Your Information <br></br>
            </a>
            <a href='#howWeStoreYourInformation'>
              4. How We Store Your Information <br></br>
            </a>
            <a href='#yourChoicesAboutYourInformation'>
              5. Your Choices About Your Information <br></br>
            </a>
            <a href='#childrensPrivacy'>
              6. Children’s Privacy
              <br></br>
            </a>
            <a href='#otherWebsitesAndServices'>
              7. Other Websites and Services <br></br>
            </a>
            <a href='#howToContactUsAboutDeceasedUser'>
              8. Data Protection <br></br>
            </a>
            <a href='#howToContactUsAboutDeceasedUser'>
              9. How to Contact Us About a Deceased User <br></br>
            </a>
            <a href='#howToContactUs'>
              10. How to Contact Us <br></br>
            </a>
            <a href='#changesToOurPrivacyPolicy'>
              11. Changes to Our Privacy Policy <br></br>
            </a>  
            <a href='#accountDeletion'>
              12. Account deletion <br></br>
            </a>            
            <section id='informationWeCollect'>
              <br></br>
              <br></br>
              <h4>1. Information We Collect</h4>
              We collect the following types of information. <br></br>
              <br></br>Information you provide us directly:from the Services
              whether submitted by you or others. <br></br>
              <br></br>Your username, user ID, password and e-mail address when
              you register for a BetnClubs account. <br></br>
              <br></br>Profile information that you provide for your user
              profile (e.g., first and last name, picture, phone number). This
              information allows us to help you or others be “found” on
              BetnClubs. <br></br>
              <br></br>User Content (e.g., photos, comments, and other
              materials) that you post to the Service. <br></br>
              <br></br>Communications between you and BetnClubs. For example, we
              may send you Service-related emails (e.g., account verification,
              changes/updates to features of the Service, technical and security
              notices). Note that you may not opt out of Service-related
              e-mails.
              <br></br>
              <br></br>
              <h5>Analytics information: </h5>
              We use third-party analytics tools to help us measure
              traffic and usage trends for the Service. These tools collect
              information sent by your device or our Service, and may include
              the web pages you visit, add-ons, and other information that
              assists us in improving the Service. We collect and use this
              analytics information with analytics information from other Users
              so that it cannot reasonably be used to identify any particular
              individual User. <br></br>
              <br></br>We also collect data about your activity in our Services,
              such as, but not limited to: ID user, bets placed, amounts, time
              of placing bet, history of bets, chips record, transactions, trade
              record, buying history etc. in order to improve our Services and
              to create and/or improve features and/or new services. <br></br>
              <br></br>This type of data will help us personalize your
              experience in relation with our Services and will help us develop
              new Services or Products inside/outside of BetnClubs, and/or share
              the data with our partners, and/or use it for our or third party
              research and development in the behaviour of Users, and/or
              advertisement purposes. We will make sure that the data will not
              contain any personal information and it will not be possible to
              correlate the data to real names or persons. <br></br>
              <br></br>
              <h5>Cookies and similar technologies:</h5>
              When you visit the Service, we may use cookies and
              similar technologies like pixels, web beacons, and local storage
              to collect information about how you use BetnClubs and provide
              features to you.
              <br></br>
              <br></br>We may ask advertisers or other partners to serve ads or
              services to your devices, which may use cookies or similar
              technologies placed by us or the third party. <br></br>
              <br></br>More information is available in our About Cookies
              section.
              <br></br>
              <br></br>
              <h5>Log file information:</h5>
              Log file information is automatically reported by your
              browser each time you make a request to access (i.e., visit) a web
              page or app. It can also be provided when the content of the
              webpage or app is downloaded to your browser or device. <br></br>
              <br></br>When you use our Service, our servers automatically
              record certain log file information, including your web request,
              Internet Protocol (“IP”) address, browser type, referring / exit
              pages and URLs, number of clicks and how you interact with links
              on the Service, domain names, landing pages, pages viewed, and
              other such information. We may also collect similar information
              from emails sent to our Users which then help us track which
              emails are opened and which links are clicked by recipients. The
              information allows for more accurate reporting and improvement of
              the Service. <br></br>
              <br></br>
              <h5>Device identifiers:</h5>
               When you use a mobile device like a tablet or phone to
              access our Service, we may access, collect, monitor, store on your
              device, and/or remotely store one or more “device identifiers.”
              Device identifiers are small data files or similar data structures
              stored on or associated with your mobile device, which uniquely
              identify your mobile device. A device identifier may be data
              stored in connection with the device hardware, data stored in
              connection with the device’s operating system or other software,
              or data sent to the device by BetnClubs. <br></br>
              <br></br>A device identifier may deliver information to us or to a
              third party partner about how you browse and use the Service and
              may help us or others provide reports or personalized content and
              ads. Some features of the Service may not function properly if use
              or availability of device identifiers is impaired or disabled.
              <br></br>
              <br></br>
              <h5>Metadata:</h5>
              Metadata is usually technical data that is associated
              with User Content. For example, Metadata can describe how, when
              and by whom a piece of User Content was collected and how that
              content is formatted. <br></br>
              <br></br>Users can add or may have Metadata added to their User
              Content including a hashtag (e.g., to mark keywords when you post
              a photo), geotag (e.g., to mark your location to a photo),
              comments or other data. This makes your User Content more
              searchable by others and more interactive. If you geotag your
              photo or tag your photo using other’s APIs then, your latitude and
              longitude will be stored with the photo and searchable (e.g.,
              through a location or map feature) if your photo is made public by
              you in accordance with your privacy settings.
            </section>
            <section id='howWeUseYourInformation'>
              <br></br>
              <br></br>
              <br></br>
              <h4>2. How We Use Your Information</h4>
              In addition to some of the specific uses of information
              we describe in this Privacy Policy, we may use information that we
              receive to: <br></br>
              <br></br>help you efficiently access your information after you
              sign in; <br></br>
              <br></br>remember information so you will not have to re-enter it
              during your visit or the next time you visit the Service;{" "}
              <br></br>
              <br></br>provide personalized content and information to you and
              others, which could include online ads or other forms of
              marketing;
              <br></br>
              <br></br>provide, improve, test, and monitor the effectiveness of
              our Service; <br></br>
              <br></br>develop and test new products and features; <br></br>
              <br></br>monitor metrics such as total number of visitors,
              traffic, and demographic patterns; <br></br>
              <br></br>diagnose or fix technology problems; <br></br>
              <br></br>automatically update the BetnClubs application on your
              device BetnClubs or other Users may run contests; <br></br>
              <br></br>special offers or other events or activities (“Events”)
              on the Service. If you do not want to participate in an Event, do
              not use the particular Metadata (i.e. hashtag or geotag)
              associated with that Event.
            </section>
            <section id='sharingOfYourInformation'>
              <br></br>
              <br></br>
              <br></br>
              <h4>3. Sharing Of Your Information</h4>
              We will not rent or sell your information to third
              parties outside BetnClubs (or the group of companies of which
              BetnClubs is a part) without your consent, except as noted in this
              Policy. <br></br>
              <br></br>
              <br></br>
              <h5>Parties with whom we may share your information:</h5>
              We may share User Content and your information (including
              but not limited to, information from cookies, log files, device
              identifiers, location data, and usage data) with businesses that
              are legally part of the same group of companies that own BetnClubs
              is part of, or that become part of that group (“Affiliates”).
              <br></br>
              <br></br>Affiliates may use this information to help provide,
              understand, and improve the Service (including by providing
              analytics) and Affiliates’ own services (including by providing
              you with better and more relevant experiences). But these
              Affiliates will honor the choices you make about who can see the
              information you choose to make it public. <br></br>
              <br></br>We also may share your information as well as information
              from tools like cookies, log files, and device identifiers and
              location data, with third-party organizations that help us provide
              the Service to you (“Service Providers”). Our Service Providers
              will be given access to your information as is reasonably
              necessary to provide the Service under reasonable confidentiality
              terms.<br></br>
              <br></br>We may also share certain information such as cookie data
              with third-party advertising partners. This information would
              allow third-party ad networks to, among other things, deliver
              targeted advertisements that they believe will be of most interest
              to you.
              <br></br>
              <br></br>We may remove parts of data that can identify you and
              share anonymized data with other parties. We may also combine your
              information with other information in a way that it is no longer
              associated with you and share that aggregated information.{" "}
              <br></br>
              <br></br>
              <br></br>
              <h5>
                Parties with whom you may choose to share your User Content:
              </h5>
              Any information or content that you voluntarily disclose
              for posting to the Service, such as User Content, becomes
              available to the public, as controlled by any applicable privacy
              settings that you set. To change your privacy settings on the
              Service, please change your profile setting. Once you have shared
              User Content or made it public, that User Content may be re-shared
              by others.
              <br></br>
              <br></br>Subject to your profile and privacy settings, any User
              Content that you make public is searchable by other Users and
              subject to use under our BetnClubs API. The use of the BetnClubs
              API is subject to the API Terms of Use which incorporates the
              terms of this Privacy Policy. <br></br>
              <br></br>If you remove information that you choose to make public
              that you posted to the Service, copies may remain viewable in
              cached and archived pages of the Service (except any data that is
              considered personal), or if other Users or third parties using the
              BetnClubs API have copied or saved that information (except any
              data that is considered personal data). <br></br>
              <br></br>
              <br></br>
              <h5>What happens in the event of a change of control:</h5>
              If we sell or otherwise transfer part or the whole of
              BetnClubs or our assets to another organization (e.g., in the
              course of a transaction like a merger, acquisition, bankruptcy,
              dissolution, liquidation), your information such as name and email
              address, User Content and any other information collected through
              the Service may be among the items sold or transferred. <br></br>
              <br></br>You will continue to own your User Content. The buyer or
              transferee will have to honor the commitments we have made in this
              Privacy Policy. <br></br>
              <br></br>
              <br></br>
              <h5>Responding to legal requests and preventing harm:</h5>
              We may access, preserve and share your information in
              response to a legal request (like a search warrant, court order or
              subpoena) if we have a good faith belief that the law requires us
              to do so. This may include responding to legal requests from
              jurisdictions outside of Romania where we have a good faith belief
              that the response is required by law in that jurisdiction, affects
              users in that jurisdiction, and is consistent with internationally
              recognized standards. We may also access, preserve and share
              information when we have a good faith belief it is necessary to:
              detect, prevent and address fraud and other illegal activity; to
              protect ourselves, you and others, including as part of
              investigations; and to prevent death or imminent bodily harm.
              Information we receive about you may be accessed, processed and
              retained for an extended period of time when it is the subject of
              a legal request or obligation, governmental investigation, or
              investigations concerning possible violations of our terms or
              policies, or otherwise to prevent harm.
            </section>
            <section id='howWeStoreYourInformation'>
              <br></br>
              <br></br>
              <br></br>
              <h4>4.How We Store Your Information</h4>
              
              <br></br>
              <h5>Storage and Processing:</h5>
              Your information collected through the Service may be
              stored and processed in Romania or any other country in which
              <br></br>
              <br></br>BetnClubs, its Affiliates or Service Providers maintain
              facilities. BetnClubs, its Affiliates, or Service Providers may
              transfer information that we collect about you, including personal
              information across borders and from your country or jurisdiction
              to other countries or jurisdictions around the world. If you are
              located in the European Union or other regions with laws governing
              data collection, please note that we may transfer information,
              including personal information, to a country and jurisdiction that
              does not have the same data protection laws as your jurisdiction.{" "}
              <br></br>
              <br></br>By registering for and using the Service you consent to
              the transfer of information to Romania or to any other country in
              which BetnClubs, its Affiliates or Service Providers maintain
              facilities and the use and disclosure of information about you as
              described in this Privacy Policy. <br></br>
              <br></br>We use commercially reasonable safeguards to help keep
              the information collected through the Service secure and take
              reasonable steps (such as requesting a unique password) to verify
              your identity before granting you access to your account. However,
              BetnClubs cannot ensure the security of any information you
              transmit to BetnClubs or guarantee that information on the Service
              may not be accessed, disclosed, altered, or destroyed. <br></br>
              <br></br>Please do your part to help us. You are responsible for
              maintaining the secrecy of your unique password and account
              information, and for controlling access to emails between you and
              BetnClubs, at all times. Your privacy settings may also be
              affected by changes the social media services you connect to
              BetnClubs make to their services. We are not responsible for the
              functionality, privacy, or security measures of any other
              organization.
            </section>
            <section id='yourChoicesAboutYourInformation'>
              <br></br>
              <br></br>
              <br></br>
              <h4>5. Your Choices About Your Information</h4>
              
              <br></br>
              <h5>Your account information and profile/privacy settings:</h5>
              Update your account at any time by logging in and
              changing your profile settings. <br></br>
              <br></br>Unsubscribe from email communications from us by clicking
              on the “unsubscribe link” provided in such communications. As
              noted above, you may not opt out of Service-related communications
              (e.g., account verification, purchase and billing confirmations
              and reminders, changes/updates to features of the Service,
              technical and security notices). <br></br>
              <br></br>All personal data will be subjected to regulations
              regarding them <br></br>
              <br></br>
              <br></br>
              <h5>How long we keep your User Content:</h5>
              Following termination or deactivation of your account,
              BetnClubs, its Affiliates, or its Service Providers may retain
              information (including your profile information) and User Content
              for a commercially reasonable time for backup, archival, and/or
              audit purposes. We may keep data for longer term for statistical
              purposes, dutifully anonymized when required by the law. <br></br>
              <br></br>Learn more about deleting your account, see point 8.
            </section>
            <section id='childrensPrivacy'>
              <br></br>
              <br></br>
              <br></br>
              <h4>6. Children’s Privacy</h4>
              BetnClubs does not knowingly collect or solicit any
              information from anyone under the age of 12 or knowingly allow
              such persons to register for the Service. The Service and its
              content are not directed at children under the age of 12. In the
              event that we learn that we have collected personal information
              from a child under age 12 without parental consent, we will delete
              that information as quickly as possible. If you believe that we
              might have any information from or about a child under 12, please
              contact us.
            </section>
            <section id='otherWebsitesAndServices'>
              <br></br>
              <br></br>
              <br></br>
              <h4>7. Other Web Sites And Services</h4>
              We are not responsible for the practices employed by any
              websites or services linked to or from our Service, including the
              information or content contained within them. Please remember that
              when you use a link to go from our Service to another website or
              service, our Privacy Policy does not apply to those third-party
              websites or services. Your browsing and interaction on any
              third-party website or service, including those that have a link
              on our website, are subject to that third party’s own rules and
              policies. In addition, you agree that we are not responsible and
              do not have control over any third-parties that you authorize to
              access your User Content. If you are using a third-party website
              or service and you allow them to access your User Content you do
              so at your own risk.
            </section>
            <section id='dataProtection'>
              <br></br>
              <br></br>
              <br></br>
              <h4>8. Data Protection</h4>
              <br></br>BetnClubs has encrypted and stored players’ data,.
              <br></br>
              <br></br>If any player wishes to delete his/her data from our
              platform, please contact us. We will provide contact information.
              <br></br>
              <br></br>BetnClubs has appointed a Data Protection Officer (DPO) to
              manage and protect players’ data. <br></br>
              <br></br>We will not distribute any player personal data to third
              party organizations and will not use the personal data for any
              commercial purposes.
            </section>
            <section id='howToContactUsAboutDeceasedUser'>
              <br></br>
              <br></br>
              <br></br>
              <h4>9. How To Contact Us About A Deceased User</h4>
              In the event of the death of an BetnClubs User, please
              contact us. We will usually conduct our communication via email;
              should we require any other information, we will contact you at
              the email address you have provided in your request.
            </section>
            <section id='howToContactUs'>
              <br></br>
              <br></br>
              <br></br>
              <h4>10. How To Contact Us</h4>
              If you have any questions about this Privacy Policy or
              the Service, please find the appropriate support channel in the
              BetnClubs SUPPORT at which to contact us.
            </section>
            <section id='changesToOurPrivacyPolicy'>
              <br></br>
              <br></br>
              <br></br>
              <h4>11. Changes To Our Privacy Policy</h4>
              BetnClubs may modify or update this Privacy Policy from
              time to time, so please review it periodically. We may provide you
              additional forms of notice of modifications or updates as
              appropriate under the circumstances. Your continued use of
              BetnClubs or the Service after any modification to this Privacy
              Policy will constitute your acceptance of such modification.
            </section>
            <section id='accountDeletion'>
              <br></br>
              <br></br>
              <br></br>
              <h4>12. Account deletion</h4>
              You can delete your account by logging in the app and in the "Profile" menu under "My Account" you have the option to delete your account. All personal information related data will be deleted (username, e-mail etc).

              Additionally you can login in our web and send us a request in the "Contact" form https://betnclubs.com/contact by selecting "Privacy" for category and a message asking for account deletion. All personal information related data will be deleted (username, e-mail etc).
            </section>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyAndCookiePolicy;
