import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import React from 'react';
import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel';
import * as PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core';

const DUMMY_VIDEOS = [
  {
    _id: 0,
    videoUrl: "https://www.youtube.com/watch?v=FM6SXbjIQhs",
  },
  {
    _id: 1,
    videoUrl: "https://www.youtube.com/shorts/ucWsdPSt5XQ"
  },
  {
    _id: 2,
    videoUrl: "https://www.youtube.com/watch?v=97q3NetpjSY"
  },
  {
    _id: 3,
    videoUrl: "https://www.youtube.com/watch?v=RGApPq6ToNE"
  },
  {
    _id: 4,
    videoUrl: "https://www.youtube.com/watch?v=Yh5VtSa32Uk"
  }
];

const useStyles = makeStyles(theme => ({
  carousel: {
//   margin: theme.spacing(2)
 }
}));

const YoutubeSlide: React.FC<{
    url: string; 
    muted: boolean;
    playing : boolean; 
    key: number 
  }> = ({
    url, 
    isSelected, 
    muted, 
    playing, 
    key 
}:any) => (
  <ReactPlayer width="100%" height="270px" url={url} playing={playing} />
);

const CarouselVideo = ({ data }:any) => {
  const classes = useStyles();

  const customRenderItem = (item:any, props:any) => (
    <item.type {...item.props} {...props} />
  );

  const getVideoThumb = (videoId:any) =>`https://img.youtube.com/vi/${videoId}/default.jpg`;

  const getVideoId = (url:any) =>{
    if (url.includes("/watch")) {
        return url.substr('https://www.youtube.com/watch?v='.length, url.length);
    } else if ( url.includes("/shorts")) {
        // e.g.: "https://www.youtube.com/shorts/ucWsdPSt5XQ"
        return url.substr('https://www.youtube.com/shorts/'.length, url.length);
    }
  }

  const customRenderThumb = (children:any) =>
    children.map((item:any) => {
      const videoId = getVideoId(item.props.url);
  
      return <img key={videoId} src={getVideoThumb(videoId)} />;
  });

  return (
    <Grid item md={6} xs={12}>
      <Carousel
       autoPlay={false}
       className={classes.carousel}
       emulateTouch={true}
       showArrows={true}
       showThumbs={true}
       showStatus={true}
       infiniteLoop={true}
       renderItem={customRenderItem}
       renderThumbs={customRenderThumb}
     >
      {data.map((v:any) => (
        <YoutubeSlide
          url={v.videoUrl}
          muted
          playing={false}
          key={v._id ? v._id : v.id}
        />
      ))}
     </Carousel>
   </Grid>
  );
 };

//  YoutubeSlide.propTypes = {
//    url: PropTypes.string,
//    isSelected: PropTypes.bool
//  };

 CarouselVideo.propTypes = {
   data: PropTypes.array
 };

 CarouselVideo.defaultProps = {
  data: DUMMY_VIDEOS
 };

export default CarouselVideo;