import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import styles from "./ResponsibleGambling.module.css";

const ResponsibleGambling: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className={styles.privacyAndCookiePolicy}>
      <Row>
        <Col xl={3} lg={3} md={3} sm={12} xs={12}>
          <h1 className={styles.header1}>{t("responsibleGambling")}</h1>
        </Col>
        <Col xl={9} lg={9} md={9} sm={12} xs={12}>
          <p className={styles.text}>
            <p className={styles.topText}>
              {t('appDisclaimer')}
              <strong>{t('appDisclaimerNote')}</strong>
              {t('reassessGambling')}
            </p>

            <h4>{t('responsibleGamblingTitle')}</h4>
            <ul>
              <li>{t('gamblingEntertainment')}</li>
              <li>{t('affordableGambling')}</li>
              <li>{t('avoidChasingLosses')}</li>
              <li>{t('monitorTimeSpent')}</li>
              <li>{t('balanceWithOtherActivities')}</li>
              <li>{t('takeRegularBreaks')}</li>
              <li>{t('avoidGamblingUnderInfluence')}</li>
              <li>{t('reflectOnSpending')}</li>
              <li>{t('understandYourChances')}</li>
              <li>{t('problemGamblingSupport')}</li>
            </ul>

            <h4>{t('problemGamblingTitle')}</h4>
            <ul>
              <li>{t('spendingMoreThanAfford')}</li>
              <li>{t('difficultyManagingGambling')}</li>
              <li>{t('argumentsAboutMoney')}</li>
              <li>{t('lossOfInterest')}</li>
              <li>{t('constantThoughtsAboutGambling')}</li>
              <li>{t('lyingAboutGambling')}</li>
              <li>{t('chasingLosses')}</li>
              <li>{t('gamblingUntilMoneyGone')}</li>
              <li>{t('financialTroubleDueToGambling')}</li>
              <li>{t('increasedBetsForExcitement')}</li>
              <li>{t('neglectingResponsibilities')}</li>
              <li>{t('negativeEmotions')}</li>
            </ul>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ResponsibleGambling;
