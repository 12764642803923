import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Modal, InputGroup } from "react-bootstrap";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook, FaApple } from "react-icons/fa";
import {
  fetchSignInMethodsForEmail,
  signInWithPopup
} from "firebase/auth";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { browserName } from "react-device-detect";

import _ from "lodash";

import { useAxios } from "../../../context/AxiosProvider";
import { useAuth } from "../../../context/AuthProvider";
import { useTranslation } from "react-i18next";

import {
  // GoogleAuthProvider,
  // FacebookAuthProvider,
  OAuthProvider,
} from "firebase/auth";

import {
  appleProvider,
  auth,
  facebookProvider,
  googleProvider
} from "../../../firebase";
import { getWebParams } from "../../../helpers/functions/getWebParams";
import { getCountryAndLanguageCode } from "../../../helpers/functions/getCountryAndLanguageCode";

import styles from "./LoginModal.module.css";

import  ConfirmJoinModal from "../confirm-join/ConfirmJoinModal";

const LoginModal: React.FC<{
  isLoginModalVisible: boolean;
  handleSetIsLoginModalVisible: (value: boolean) => void;
  handleSetIsForgotPasswordModalVisible: (value: boolean) => void;
}> = ({
  isLoginModalVisible,
  handleSetIsLoginModalVisible,
  handleSetIsForgotPasswordModalVisible
}) => {
    const { storeToken, authToken } = useAuth();
    const [validated, setValidated] = useState<boolean>(false);
    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [country, setCountry] = useState<string>();
    const [language, setLanguage] = useState<string>();
    const [passwordType, setPasswordType] = useState<string>("password");
    const [
      isConfirmJoinModalVisible,
      setIsConfirmJoinModalVisible
    ] = useState(false);
    const [signinPayload, setSinginPayload] = useState<object>({});
    const [otherProvider, setOtherProvider] = useState<any>();
    const [otherProviderName, setOtherProviderName] = useState<string>("");
    const [provider, setProvider] = useState<any>();
    const [providerRes, setProviderRes] = useState<any>();
    const [providerName, setProviderName] = useState<string>("");
    const [pendingCred, setPendingCred] = useState<any>();

    const { UserLogin, UserGoogleLogin, UserFacebookLogin, UserAppleLogin } =
      useAxios();
    const { t } = useTranslation();

    useEffect(() => {
      async function getLanguageAndCountry() {
        const { countryCode, languageCode } = await getCountryAndLanguageCode();
        setCountry(countryCode);
        setLanguage(languageCode);
      }
      getLanguageAndCountry();
      return () => {
        setIsError(false);
        setErrorMessage("");
      };
    }, []);

    const togglePassword = () => {
      setPasswordType(passwordType === "password" ? "text" : "password");
    };

    const handleLogin = async (event: any) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        return;
      } else {
        event.preventDefault();
        if (!_.isEmpty(username) && !_.isEmpty(password))
          try {
            const response = await UserLogin({
              username: username!,
              password: password!
            });
            const token = response?.data;
            storeToken(token);
            setValidated(true);
          } catch (error) {
            setIsError(true);
            setErrorMessage("Error logging in.");
            setValidated(false);
          }
      }
      setValidated(true);
    };

    const handleGoogleLogin = async () => {
      const app_version = "web";
      try {
        const googleRes = await variousSocialAccountsCheck(auth, googleProvider);
        const username: string = googleRes?.user?.displayName?.replace(
          /\s/g,
          ""
        )!;
        await UserGoogleLogin({
          google_object: { user: googleRes?.user },
          device_info: getWebParams({ username: username }, "LOG_IN"),
          country,
          language,
          app_version,
          // link_social,
          // create_account
        });
      } catch (error: any) {
        setIsError(true);
        setErrorMessage(error.response.data.detail);
      }
    };

    const handleFacebookLogin = async () => {
      const app_version = "web";
      try {
        const facebookRes = await variousSocialAccountsCheck(auth, facebookProvider);
        const username: string = facebookRes?.user?.displayName?.replace(
          /\s/g,
          ""
        )!;

        await UserFacebookLogin({
          facebook_object: { user: facebookRes?.user },
          device_info: getWebParams({ username: username }, "LOG_IN"),
          username,
          language,
          app_version,
          // link_social,
          // create_account
        });
      } catch (error: any) {
        setIsError(true);
        try {
          setErrorMessage(error.response.data.detail);
        } catch (error: any) {
          setErrorMessage("Unknown error happened.");
        }
      }
    };

    const handleAppleLogin = async () => {
      const app_version = "web";
      try {
        const appleRes = await variousSocialAccountsCheck(auth, appleProvider);
        const username: string = appleRes?.user?.displayName?.replace(/\s/g, "")!;

        // Apple credential
        // const credential = OAuthProvider.credentialFromResult(appleRes);
        // const accessToken = credential?.accessToken;
        // const idToken = credential?.idToken;
        // const res = 
        await UserAppleLogin({
          apple_object: { user: appleRes?.user },
          device_info: getWebParams({ username: username }, "LOG_IN"),
          username,
          country,
          language,
          app_version,
          // link_social,
          // create_account
        });
      } catch (error: any) {
        setIsError(true);
        setErrorMessage(error.response.data.detail);
      }
    };

  const variousSocialAccountsCheck = async (auth: any, provider: any) => {
      // Encapsulates the SignIn Popup logic, and handles multiple account error safely.
      // Returns a safe and solved Response object.
      try {
        const providerRes = await signInWithPopup(auth, provider);

        return providerRes;

      } catch (error: any) {
        setIsError(true);
        if (error.code === 'auth/account-exists-with-different-credential') {
          // Retrieve the pending provider credential.
          const pendingCred = OAuthProvider.credentialFromError(error);
          setPendingCred(pendingCred);

          // Get sign-in methods on Firebase for this email
          const email = error.customData.email;
          const other_methods: string[] = await fetchSignInMethodsForEmail(auth, email);

          // get 1st different provider (attempted:social network to link)
          if (other_methods.indexOf(provider.providerId, 0) > -1) {
            other_methods.splice(other_methods.indexOf(provider.providerId, 0), 1);
          };
          const providerName = provider.providerId.replace(".com", "").toUpperCase();
          setProvider(provider);
          setProviderRes(providerRes);
          setProviderName(providerName);

          // Construct provider object for other provider (previous: already stored one)     
          const otherProvider = await getProviderForProviderId(other_methods);
          const otherProviderName = otherProvider.providerId.replace(".com", "").toUpperCase();
          setOtherProvider(otherProvider);
          setOtherProviderName(otherProviderName);
          await showConfirmJoinModal();
        } else {
          console.log("ERROR: unhandleable by variousSocialAccountsCheck()");
        }
      }
    };

    const showConfirmJoinModal = async () => {
      try {
        setIsConfirmJoinModalVisible(true);
      } catch (error: any) {
        setIsError(true);
        setErrorMessage(error?.response?.data?.detail);
      }
    };

    const getProviderForProviderId = (other_methods: string[]) => {
      if (other_methods[0] == "google.com") {
        return googleProvider;

      } else if (other_methods[0] == "apple.com") {
        return appleProvider;

      } else {
        return facebookProvider;
      }
    }

    return (
      <Modal
        show={isLoginModalVisible && !authToken}
        onHide={() => {
          handleSetIsLoginModalVisible(false);
          setUsername("");
          setPassword("");
          setValidated(false);
        }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleLogin} noValidate validated={validated}>
            <Form.Group>
              <Row>
                <Col>
                  <Form.Label className={styles.labelText}>
                    {t("EmailUsername")}
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    required
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col>
                  <Form.Label className={styles.labelText}>{t("Password")}</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputGroup>
                    <Form.Control
                      required
                      type={passwordType}
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    <a
                      className={
                        browserName === "Safari"
                          ? `${styles.eyeIcon} ${styles.right35}`
                          : `${styles.eyeIcon}`
                      }
                      onClick={togglePassword}
                    >
                      {passwordType === "password" ? (
                        <IoEyeOutline size={28} />
                      ) : (
                        <IoEyeOffOutline size={28} />
                      )}
                    </a>
                  </InputGroup>
                </Col>
              </Row>
            </Form.Group>
            <Row className={styles.errorMessageContainer}>
              <Col lg={12} md={12} xs={12}>
                <p className={isError ? styles.errorMessage : styles.displayNone}>
                  {errorMessage}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <Button
                  onClick={handleLogin}
                  className={`${styles.text} ${styles.button} ${styles.loginButton}`}
                >
                  {t("LogIn")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <a
                  className={`${styles.text} ${styles.forgotPasswordText} ${styles.whiteText}`}
                  onClick={() => {
                    handleSetIsLoginModalVisible(false);
                    handleSetIsForgotPasswordModalVisible(true);
                  }}
                >
                  {t("ForgotPassword")}
                </a>
              </Col>
            </Row>
            <Row className={styles.orTextContainer}>
              <Col lg={12} md={12} xs={12}>
                <p
                  className={`${styles.text} ${styles.orText} ${styles.whiteText}`}
                >
                  {t("Or")}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <Button
                  className={`${styles.text} ${styles.button} ${styles.mgBottom10}`}
                  onClick={handleGoogleLogin}
                >
                  <FcGoogle size={28} className={styles.icon} /> 
                  {t("ContinueGoogle")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <Button
                  onClick={handleFacebookLogin}
                  className={`${styles.text} ${styles.button} ${styles.mgBottom10}`}
                >
                  <FaFacebook
                    size={28}
                    className={`${styles.icon} ${styles.facebookIcon}`}
                  />
                  {t("ContinueFacebook")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} xs={12}>
                <Button
                  className={`${styles.text} ${styles.button}`}
                  onClick={handleAppleLogin}
                >
                  <FaApple size={28} color={"#000"} className={styles.icon} />{" "}
                  {t("ContinueApple")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <ConfirmJoinModal
          isConfirmJoinModalVisible={isConfirmJoinModalVisible}
          providerName={providerName}
          otherProvider={otherProvider}
          otherProviderName={otherProviderName}
          pendingCred={pendingCred}
          handleSetIsConfirmJoinModalVisible={
            setIsConfirmJoinModalVisible
          }
        />
      </Modal>
    );
  };

export default LoginModal;

