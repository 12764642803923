import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  FaRegEnvelope,
  FaInstagram,
  FaFacebook,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn
} from "react-icons/fa";

import _ from "lodash";

import { useAxios } from "../../context/AxiosProvider";
import { FEEDBACK_OPTIONS } from "../../constants/feedback-options";

import styles from "./Contact.module.css";

const Contact: React.FC<{}> = () => {
  const { PostFeedback } = useAxios();

  const { t } = useTranslation();
  const [name, setName] = useState<string>("");
  const [enquiry, setEnquiry] = useState<string>("");
  const [type, setType] = useState<number>(0);
  const [validated, setValidated] = useState<boolean>(false);

  const handleSend = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (
      typeof type !== "number" &&
      !_.isEmpty(type) &&
      !_.isEmpty(name) &&
      !_.isEmpty(enquiry)
    ) {
      event.preventDefault();
      try {
        await PostFeedback({ name: name, text: enquiry, type: type });
      } catch (error) {
        setValidated(false);
      }
    }
    setValidated(true);
  };

  return (
    <Container fluid className={styles.contact}>
      <Row className={styles.row}>
        <Col
          xl={6}
          lg={6}
          md={6}
          className={`d-md-block d-none ${styles.leftCol}`}
        >
          <Row>
            <h2 className={`${styles.header1} ${styles.header2}`}>
              {t("CustomerSupport")}
            </h2>
          </Row>
          <Row className={styles.mgTop45}>
            <Col xl={1} lg={1} md={1} sm={1} xs={1}>
              <FaRegEnvelope size={30} color={"#ECBB40"} />
            </Col>
            <Col
              xl={11}
              lg={11}
              md={11}
              sm={11}
              xs={11}
              className={styles.linkTextCol}
            >
              <a
                href='mailto:care@betnclubs.com'
                target='_blank'
                className={styles.linkText}
              >
                care@betnclubs.com
              </a>
            </Col>
          </Row>
          <Row className={styles.mgTop40}>
            <Col xl={1} lg={1} md={1} sm={1} xs={1}>
              <FaInstagram size={30} color={"#ECBB40"} />
            </Col>
            <Col
              xl={2}
              lg={2}
              md={11}
              sm={11}
              xs={11}
              className={styles.linkTextCol}
            >
              <a
                href='https://www.instagram.com/betnclubs/'
                target='_blank'
                className={styles.linkText}
              >
                @betnclubs
              </a>
            </Col>
          </Row>
          <Row className={styles.mgTop40}>
            <Col xl={1} lg={1} md={1} sm={1} xs={1}>
              <FaFacebook size={30} color={"#ECBB40"} />
            </Col>
            <Col
              xl={11}
              lg={11}
              md={11}
              sm={11}
              xs={11}
              className={styles.linkTextCol}
            >
              <a
                href='https://www.facebook.com/people/Betn-Clubs/100076796988065/'
                target='_blank'
                className={styles.linkText}
              >
                Bet'nClubs
              </a>
            </Col>
          </Row>
          <Row className={styles.mgTop40}>
            <Col xl={1} lg={1} md={1} sm={1} xs={1}>
              <FaLinkedinIn size={30} color={"#ECBB40"} />
            </Col>
            <Col
              xl={11}
              lg={11}
              md={11}
              sm={11}
              xs={11}
              className={styles.linkTextCol}
            >
              <a
                href='https://www.linkedin.com/company/betnclubs'
                target='_blank'
                className={styles.linkText}
              >
                Bet'nClubs
              </a>
            </Col>
          </Row>
          <Row className={styles.mgTop40}>
            <Col xl={1} lg={1} md={1} sm={1} xs={1}>
              <FaTwitter size={30} color={"#ECBB40"} />
            </Col>
            <Col
              xl={11}
              lg={11}
              md={11}
              sm={11}
              xs={11}
              className={styles.linkTextCol}
            >
              <a
                href='https://twitter.com/BetnClubs'
                target='_blank'
                className={styles.linkText}
              >
                Bet'nClubs
              </a>
            </Col>
          </Row>
          <Row className={styles.mgTop40}>
            <Col xl={1} lg={1} md={1} sm={1} xs={1}>
              <FaYoutube size={30} color={"#ECBB40"} />
            </Col>
            <Col
              xl={11}
              lg={11}
              md={11}
              sm={11}
              xs={11}
              className={styles.linkTextCol}
            >
              <a
                href='https://www.youtube.com/@betnclubs'
                target='_blank'
                className={styles.linkText}
              >
                Bet'nClubs
              </a>
            </Col>
          </Row>
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <Row>
            <Col>
              <h2 className={`${styles.header1} ${styles.header2}`}>
                {t("ContactUs")}
              </h2>
            </Col>
          </Row>
          <Form onSubmit={handleSend} noValidate validated={validated}>
            <Form.Group className={styles.formContainer}>
              <Row>
                <Col>
                  <Form.Select
                    className={`${styles.dropdown} mb-3`}
                    onChange={(e) => {
                      setType(parseInt(e.target.value));
                    }}
                    required
                  >
                    <option key={""} value={""}>
                      {t("PleaseSelectFeedbackCategory")}
                    </option>
                    {FEEDBACK_OPTIONS.map((element, index) => {
                      return <option value={index}>{element!.name}</option>;
                    })}
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    placeholder={t("Name")}
                    className={`${styles.formInput} mb-4`}
                    required
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Control
                    placeholder={t("YourEnquiry") + "..."}
                    className={`${styles.formInput} mb-4`}
                    required
                    value={enquiry}
                    as='textarea'
                    rows={3}
                    onChange={(event) => setEnquiry(event.target.value)}
                  />
                </Col>
              </Row>
              <Row className={styles.buttonContainer}>
                <Col>
                  <Button
                    type='submit'
                    className={styles.button}
                    onClick={handleSend}
                  >
                    {t("Send")}
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
