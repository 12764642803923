import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAuth } from "./AuthProvider";
import { useAxios } from "./AxiosProvider";
import {CurrentUser} from "../types/current-user";
import { Props} from "../types/props";


type UserProfileContextData = {
  currentUser: CurrentUser | null;
  setCurrentUser: Dispatch<SetStateAction<CurrentUser | null>> | null;
  getCurrentUserProfile: () => Promise<void> | null;
};

export const UserProfileContext = React.createContext<UserProfileContextData>({
  currentUser: null,
  setCurrentUser: null,
  getCurrentUserProfile: () => null,
});

export const useUserProfile = () => React.useContext(UserProfileContext);

export const UserProfileProvider: React.FC<Props> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const { GetCurrentProfile } = useAxios();
  const { authToken } = useAuth();

  const getCurrentUserProfile = async () => {
    const response = await GetCurrentProfile();
    const data = response?.data;
    setCurrentUser(data);
  };

  useEffect(() => {
    if (authToken) getCurrentUserProfile();
  }, [authToken]);

  const value: UserProfileContextData = {
    currentUser,
    setCurrentUser,
    getCurrentUserProfile,
  };

  return (
    <UserProfileContext.Provider value={value}>
      {children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileProvider;
