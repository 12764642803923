import React from "react";
import {
  FaRegEnvelope,
  FaInstagram,
  FaFacebook,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn
} from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import styles from "./Footer.module.css";

const Footer: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className={styles.footer}>
      <Row className={styles.container}>
        <Col sm={12} className={`d-md-none d-sm-block`}>
          <Row>
            <h1 className={styles.header1}>Bet'nClubs</h1>
          </Row>
          <Row>
            <h2 className={styles.header2}>{t("BettingService")}</h2>
          </Row>
          <Row className={styles.alCenter}>
            <Col sm={6}>
              <p className={styles.contactFollowText}>{t("FollowUs")}</p>
            </Col>
            <Col sm={6} className={styles.textAlRight}>
              <p className={styles.contactFollowText}>{t("ContactUs")}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className={styles.alCenter}>
              <Row>
                <Col>
                  <a
                    href='https://www.instagram.com/betnclubs/'
                    target='_blank'
                    className={styles.icon}
                  >
                    <FaInstagram />
                  </a>
                </Col>
                <Col>
                  <a
                    href='https://www.facebook.com/people/Betn-Clubs/100076796988065/'
                    target='_blank'
                    className={styles.icon}
                  >
                    <FaFacebook />
                  </a>
                </Col>
                <Col>
                  <a
                    href='https://www.linkedin.com/company/betnclubs'
                    target='_blank'
                    className={styles.icon}
                  >
                    <FaLinkedinIn />
                  </a>
                </Col>
                <Col>
                  <a
                    href='https://twitter.com/BetnClubs'
                    target='_blank'
                    className={styles.icon}
                  >
                    <FaTwitter />
                  </a>
                </Col>
                <Col>
                  <a
                    href='https://www.youtube.com/@betnclubs'
                    target='_blank'
                    className={styles.icon}
                  >
                    <FaYoutube />
                  </a>
                </Col>
              </Row>
            </Col>
            <Col sm={6} className={`${styles.alCenter} ${styles.textAlRight}`}>
              <FaRegEnvelope size={22} color={"#ECBB40"} />
              <a
                className={styles.emailText}
                href='mailto:care@betnclubs.com'
                target='_blank'
              >
                care@betnclubs.com
              </a>
            </Col>
          </Row>
          <Row className={styles.borderBottom}></Row>
          <Row>
            <a href='/terms-and-conditions' className={styles.link}>
              {t("TermsAndConditions")}
            </a>
          </Row>
          <Row>
            <a href='/privacy-and-cookie-policy' className={styles.link}>
              {t("PrivacyAndCookiePolicy")}
            </a>
          </Row>
          <Row>
            <a href='/responsible-gambling' className={styles.link}>
              {t("responsibleGambling")}
            </a>
          </Row>
          <Row className={styles.textAlCenter}>
            <p className={styles.copyrightText}>
              © 2022 Bet’nClubs. {t("AllRightsReserved")}.
            </p>
            <p className={`${styles.copyrightText} ${styles.boldDisclaimer}`}>
              {t("disclaimer")}
            </p>
            <p className={styles.copyrightText}>
              {t("weRequireAge")}
            </p>
          </Row>
        </Col>
        <Col md={4} className={`d-md-block d-none ${styles.pdLeft0}`}>
          <Row className={styles.topLeftContainer}>
            <Col md={7}>
              <p className={styles.contactFollowText}>{t("ContactUs")}</p>
            </Col>
            <Col md={1} className={styles.envelopeIcon}>
              <FaRegEnvelope size={22} color={"#ECBB40"} />
            </Col>
            <Col md={4} classNme={styles.emailTextCol}>
              <a
                className={styles.emailText}
                href='mailto:care@betnclubs.com'
                target='_blank'
              >
                care@betnclubs.com
              </a>
            </Col>
          </Row>
          <Row className={styles.bottomLeftContainer}>
            <Col>
              <Row>
                <a href='/terms-and-conditions' className={styles.link}>
                  {t("TermsAndConditions")}
                </a>
              </Row>
            </Col>
            <Col>
              <Row>
                <a href='/privacy-and-cookie-policy' className={styles.link}>
                  {t("PrivacyAndCookiePolicy")}
                </a>
              </Row>
            </Col>
            <Col>
              <Row>
                <a href='/responsible-gambling' className={styles.link}>
                  {t("responsibleGambling")}
                </a>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col md={4} className={`d-md-block d-none ${styles.middleContainer}`}>
          <p className={styles.copyrightText}>
            © 2022 Bet’nClubs. {t("AllRightsReserved")}.
          </p>
          <p className={`${styles.copyrightText} ${styles.boldDisclaimer}`}>
            {t("disclaimer")}
          </p>
          <p className={styles.copyrightText}>
            {t("weRequireAge")}
          </p>
        </Col>
        <Col md={4} className={`d-md-block d-none ${styles.rightContainer}`}>
          <Row className={styles.topRightContainer}>
            <p className={styles.contactFollowText}>{t("FollowUs")}</p>
          </Row>
          <Row>
            <Col>
              <a
                href='https://www.instagram.com/betnclubs/'
                target='_blank'
                className={styles.icon}
              >
                <FaInstagram />
              </a>
            </Col>
            <Col>
              <a
                href='https://www.facebook.com/people/Betn-Clubs/100076796988065/'
                target='_blank'
                className={styles.icon}
              >
                <FaFacebook />
              </a>
            </Col>
            <Col>
              <a
                href='https://www.linkedin.com/company/betnclubs'
                target='_blank'
                className={styles.icon}
              >
                <FaLinkedinIn />
              </a>
            </Col>
            <Col>
              <a
                href='https://twitter.com/BetnClubs'
                target='_blank'
                className={styles.icon}
              >
                <FaTwitter />
              </a>
            </Col>
            <Col>
              <a
                href='https://www.youtube.com/@betnclubs'
                target='_blank'
                className={styles.icon}
              >
                <FaYoutube />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
